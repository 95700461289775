import { useMainStore } from "."
import { Role } from "../../libs/interfaces/layouts"

export const isRole = (role: Role[] | Role) => {
    const user = useMainStore.getState().user
    if(Array.isArray(role))
    {
        return user.roles?.filter((r: Role) => role.filter((rr: Role) => rr === r).length > 0).length > 0
    }
    else
    {
        return user.roles?.filter((r: Role) => r === role).length > 0
    }
}

export const isUser = () => {
    const user = useMainStore.getState().user
    return !!user.id
}