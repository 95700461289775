import { Button, ButtonGroup, Card, Collapse } from "@blueprintjs/core"  
import { useState } from "react" 
import { IFormField } from "../../data/interfaces"
import { __ } from "@/libs/utilities"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { moduleExists } from "@/libs/layouts"
import { ISingleField } from "@/libs/scalars/interface/IScalarForm"

interface IProps extends IFormField{
    i: number
    yandex_form_field: any
    projectField: any
    className: string
    OnSubMenu : (i:number, data: any, field: string) => void
    onChange : (i:number, data: any) => void
    onSwitchUp : (i:number) => void
    onSwitchDown : (i:number) => void
    onRemove : (i:number) => void
    disabledDown : boolean
    disabledUp: boolean
}
const SingleFormField = (props: IProps) : JSX.Element =>
{
    const [type, setType] = useState(props.type)
    const [ require, setRequire] = useState( props.require )
    const [values, setValues] = useState(props.values || "")
    const [projectField, setProjectField] = useState(props.projectField)
    const [yandex_form_field, setyandex_form_field] = useState(props.yandex_form_field)
    const [count, setCount] = useState(props.count || 1)
    const [name, setName] = useState(props.name)
    const [description, setDescription] = useState(props.description) 
    
    const [add_to_rating, setadd_to_rating] = useState(props.add_to_rating) 
    const [hide_in_projects, sethide_in_projects] = useState(props.hide_in_projects) 
    const [hide_in_card, sethide_in_card] = useState(props.hide_in_card) 
    const [hide_in_page, sethide_in_page] = useState(props.hide_in_page) 
    const [highlighted, setHighlighted] = useState(props.highlighted) 
    const [isOpen, onOpen] = useState( false )
    const ChangeCount= (value: number) =>
    {
        setCount(value)
        const data = {
            require,
            name,
            description,
            count:value,
            type,
            values,
            projectField,
            yandex_form_field,
            hide_in_card,
            hide_in_projects,
            hide_in_page,
            highlighted,
            add_to_rating
        }
        props.onChange(props.i, data)
    } 
    const ChangeRequire = (value: boolean) =>
    {
        console.log( value )
        setRequire( value )
        const data = {
            require:value,
            name,
            description,
            count,
            type,
            values,
            projectField,
            yandex_form_field,
            hide_in_card,
            hide_in_projects,
            hide_in_page,
            highlighted,
            add_to_rating
        }
        props.onChange(props.i, data)
    } 
    const ChangeType= ( value: any ) =>
    {
        setType(value)
        const data = {
            require,
            name,
            description,
            count,
            type:value,
            values,
            projectField,
            yandex_form_field,
            hide_in_card,
            hide_in_projects,
            hide_in_page,
            highlighted,
            add_to_rating
        }
        props.onChange(props.i, data)
    } 
    const ChangeValues = ( value: any ) =>
    {
        setValues(value)
        const data = {
            require,
            name,
            description,
            count,
            type,
            values:value,
            projectField,
            yandex_form_field,
            hide_in_card,
            hide_in_projects,
            hide_in_page,
            highlighted,
            add_to_rating
        }
        props.onChange(props.i, data)
    } 
    const ChangeHighlighted = ( value: boolean ) =>
    {
        setHighlighted(value)
        const data = {
            require,
            name,
            description,
            count,
            type,
            values ,
            projectField,
            yandex_form_field,
            hide_in_card,
            hide_in_projects,
            hide_in_page,
            highlighted: value,
            add_to_rating
        }
        props.onChange(props.i, data)
    } 
    const ChangeProjectField= (value: any) =>
    {
        setProjectField(value)
        const data = {
            require,
            name,
            description,
            count,
            type,
            values,
            projectField:value,
            yandex_form_field,
            hide_in_card,
            hide_in_projects,
            hide_in_page,
            highlighted,
            add_to_rating
        }
        props.onChange(props.i, data)
    }  
    const ChangeName = (value : string) =>
    {
        setName(value)
        const data = {
            require,
            name:value,
            description,
            count,
            type,
            values,
            yandex_form_field,
            hide_in_card,
            hide_in_projects,
            hide_in_page,
            highlighted,
            add_to_rating
        }
        props.onChange(props.i, data)
    }
    const ChangeDescription = ( value: string ) =>
    {
        setDescription(value)
        const data = {
            require,
            name,
            description:value,
            count,
            type,
            values,
            yandex_form_field,
            hide_in_card,
            hide_in_projects,
            hide_in_page,
            highlighted,
            add_to_rating
        }
        props.onChange(props.i, data)
    } 
    const onChangeYandexValue = ( value: boolean, field : "add_to_rating" | "hide_in_projects" | "hide_in_card" | "hide_in_page" ) => {
        setadd_to_rating(value)
        const data = {
            require,
            name,
            description,
            count,
            type,
            values,
            yandex_form_field,
            add_to_rating: value,
            hide_in_card,
            hide_in_projects,
            highlighted,
            hide_in_page,
        }
        props.onChange(props.i, data)        
    }
    const onChangeVisibleValue = (values: boolean[]) => {
        sethide_in_card(values[0]) 
        sethide_in_projects(values[1])
        sethide_in_page(values[2])
        const data = {
            require,
            name,
            description,
            count,
            type,
            values,
            yandex_form_field,
            add_to_rating,
            highlighted,
            hide_in_card: values[0],
            hide_in_projects: values[1],
            hide_in_page: values[2],
        }
        props.onChange(props.i, data)
    }
    return <Card  interactive className="my-2">
        <FieldInput
            value={name}
            type={ SCALAR_TYPES.STRING }
            editable
            title={__( "Field's name" )}
            onChange={ ChangeName }
            on={ ChangeName }
        />
        <Button fill icon="more" onClick={() =>onOpen(!isOpen)} className={ isOpen ? "hidden" : ""} minimal/>
        <Collapse isOpen={isOpen}>
            <FieldInput
                value={ props.id } 
                type={SCALAR_TYPES.ID}
                title={__( "ID" )} 
            />
            <FieldInput
                value={ require }
                editable 
                type={SCALAR_TYPES.BOOLEAN}
                title={__( "Required field" )}
                onChange={ ChangeRequire }
                on={ ChangeRequire }
            />
            <FieldInput
                value={description}
                type={SCALAR_TYPES.STRING}
                editable
                title={__( "Field's description" )}
                onChange={ ChangeDescription }
                on={ ChangeDescription }
            />
            <FieldInput
                value={count}
                type={SCALAR_TYPES.SLIDER}
                title={__( "Count of fields" )}
                min={1}
                max={20}
                stepSize={1}
                editable
                onChange={ ChangeCount }
                on={ ChangeCount }
            />
            <FieldInput
                value={type}
                type={SCALAR_TYPES.TAGS}
                values={ getFormFieldTypes().map( t => ({ ...t, title: __(t.title) }) ) }
                editable={true} 
                className={` form-control dark input  ${props.className} `}
                title={__( "Field's type" )}
                onChange={ ChangeType }
                on={ ChangeType }
            />
            {
                [ SCALAR_TYPES.SELECT, SCALAR_TYPES.CHECKBOX, SCALAR_TYPES.RADIO, SCALAR_TYPES.TAGS ].filter(typ => { 
                    return typ.toString() === type.toString()
                }).length > 0
                    &&
                    <FieldInput
                        value={values}
                        type={ SCALAR_TYPES.STRING }
                        editable
                        title={__( "Variants of answers" )}
                        commentary={__("per slash")}
                        onChange={ ChangeValues }
                        on={ ChangeValues }
                    />
            }
            {
                true
                    &&
                    <FieldInput
                        value={highlighted}
                        type={ SCALAR_TYPES.BOOLEAN }
                        editable
                        title={__( "Highlighted" )} 
                        onChange={ ChangeHighlighted }
                        on={ ChangeHighlighted }
                        commentary={__("The value is highlighted and is always shown in the project data (unless hidden)")}
                    />
            }
            <div className="w-100">
                <FieldInput  
                    title={__("Visible")}
                    type={SCALAR_TYPES.CHECKBOX_GROUP}
                    editable
                    values={ visibles }
                    value={[
                        hide_in_card,
                        hide_in_projects,
                        hide_in_page,
                    ]}
                    onChange={value => onChangeVisibleValue( value )}
                /> 
                {
                    type === "number"
                        ?
                        <>
                            <FieldInput
                                title={__("Add the value of this field to the result of the experts' rating")}
                                type="boolean"
                                editable
                                value={add_to_rating}
                                onChange={value => onChangeYandexValue( value, "add_to_rating" )}
                            />
                        </>
                        :
                        null
                }
            </div>         
        </Collapse>
        
        <div className="d-100 d-flex justify-content-end pt-2">
            <ButtonGroup  minimal>
                <Button onClick={() => props.onSwitchUp(props.i)}      disabled={props.disabledUp}     icon="caret-up" />
                <Button onClick={() => props.onSwitchDown(props.i)}    disabled={props.disabledDown}   icon="caret-down" />
                <Button onClick={() => props.onRemove(props.i)}        icon="minus"/>
            </ButtonGroup>
        </div>
        
        
    </Card>
}
export default SingleFormField
export const getFormFieldTypes = () =>
{
    return [
        {
            _id:"string",
            title:"String",
            type: "text",
            description : ""
        },
        {
            _id:"number",
            title:"Number",
            type: "number",
            description : ""
        }, 
        {
            _id:"url",
            title:"Link",
            type: "text",
            description : ""
        },
        {
            _id:"email",
            title:"E-mail",
            type: "email",
            description : ""
        }, 
        {
            _id:"media",
            title:"Media",
            type: "media",
            description : ""
        }, 
        {
            _id:"tags",
            title:"Tags",
            type: "tags",
            description : ""
        }, 
        {
            _id:"radio",
            title:"Radio",
            type: "radio",
            description : ""
        }, 
        {
            _id:"checkbox",
            title:"Check boxes",
            type: "checkbox",
            description : ""
        }, 
        {
            _id:"select",
            title:"Select",
            type: "select",
            description : ""
        }, 
        // {
        //     _id:"geo",
        //     title:"Geo position",
        //     type: "geo",
        //     description : ""
        // }, 
        {
            _id:"phone",
            title:"Phone",
            type: "phone",
            description : ""
        }, 
        {
            _id:"boolean",
            title:"Yes|No",
            type: "boolean",
            description : ""
        }, 
        {
            _id:"color",
            title:"Color",
            type: "color",
            description : ""
        }, 
        {
            _id:"upload",
            title:"Upload",
            type: "upload",
            description : ""
        }, 
        {
            _id:"slider",
            title:"Slider",
            type: "slider",
            description : ""
        }, 
        {
            _id:"RussianRegions",
            title:"Russian regions",
            type: "RussianRegions",
            description : ""
        }
    ]
}

const visibles : ISingleField[] = [
    //{ _id: "add_to_rating",     title: "Add the value of this field to the result of the experts' rating" },
    { _id: "hide_in_card",      title: "Hide in cards" },
    { _id: "hide_in_projects",  title: "Hide in admin Projects" }, 
    { _id: "hide_in_page",      title: "Hide in Member's page" }, 
] 