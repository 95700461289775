import { Dialog, Position } from "@blueprintjs/core"
import React, { useState, useEffect } from "react"
import { __, } from "../utilities"
import { Loading } from "."

const ReactMarkdown = require('react-markdown')

interface IPEHelpProps {
    url: URL
    isOpen: boolean
    onClose: (isOpen: boolean) => void
}

const PEHelp = (props: IPEHelpProps) => {
    const [loading, changeLoading] = useState(true)
    const [helps, onHelps] = useState([])

    return null

    const tag_url = props.url
        ?
        props.url
        :
        "Создание-новой-страницы"
    const url: string = 'https://gitlab.com/api/v4/projects/22486508/wikis?with_content=1'
    const wiki_url: string = "https://gitlab.com/protopiahome/inner-projects/oraculi/unstable/oraculi-react/-/wikis/"
    const loadList = () => {
        fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Content-Security-Policy': 'policy',
                    'PRIVATE-TOKEN': process.env.REACT_APP_GITLAB_PRIVATE_TOKEN || ""
                }
            }
        )
            .then(response => response.json())
            .then(res => {
                //console.log(res)
                if (Array.isArray(res)) {
                    const _helps: any = res.map((help: any, i) => {
                        //console.log(help)
                        const re = new RegExp(`<a href="${wiki_url}`, `g`);
                        const newString = `<a class="landing-help-inner-link href="${wiki_url}" `
                        const cont = help.content
                            .replaceAll(`](uploads/`, `](${wiki_url}uploads/`)
                            .replaceAll(re, newString)

                        // console.log( cont )
                        return tag_url === help.slug
                            ?
                            <div className="landing-help-article" key={i}>
                                <div className="landing-help-a-title">
                                    {help.title}
                                </div>
                                <div className="landing-help-a-content">
                                    {/*
                                    <ReactMarkdown
                                        children={cont.toString()}
                                        linkTarget={"_bulk"}
                                        transformLinkUri={(href: string, children: any, title: any) => {
                                            return href
                                        }}
                                    />
                                    */}
                                </div>
                            </div>
                            :
                            null
                    })
                    onHelps(_helps)
                }
                changeLoading(false)
            },
                error => console.error("Error load help"))
    }
    useEffect(() => {
        loadList()
    }, [])

    useEffect(() => {
        loadList()
    }, [props.url])

    return <Dialog
        isOpen={props.isOpen}
        //onClose={ props.onClose }  
        className="pe-outer-container"
        title={__("Help")}
    >
        {
            loading
                ?
                <div className="w-100 h-100 d-flex flex-column align-items-center ">
                    <Loading />
                </div>
                :
                <div className="w-100 h-100 d-flex flex-column align-items-center overflow-y-auto">
                    {helps}
                </div>
        }
    </Dialog>
}

export default PEHelp