import { useEffect, useState } from 'react'
import { actions } from '../data/actions'
import { default as mainAction} from "@/modules/pe-basic-module/data/actions"
import { GET_LAND_VERSION_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { GET_FESTIVAL_DATA_ACTION } from '../data/actionTypes'
import { IFestival, ITrack, IHoneycombs, IGanre, IMilestone, ICategory, ICritery, ILand, IDestrict } from '../data/interfaces'
import { useFestDataStore } from '../data/store' 
import { ID } from '@/libs/interfaces/layouts'
import { useMainStore } from '@/settings/zustand'
import { useDataStore } from '@/modules/pe-basic-module/data/store'

const UPDATE_FEST_PERIOD = 300000

function useFestival( isLoader: boolean, festId: ID | null = null ) { 
    const [value, setValue] = useState<boolean>( isLoader )
    const getFestival = () => {
        const lastUpdateFestival: number = useFestDataStore.getState().lastUpdateFestival 
        const festival: IFestival = useFestDataStore.getState().festival  
        if( festival.url ) {
            setValue( false )
        }
        if( Date.now() - lastUpdateFestival > UPDATE_FEST_PERIOD ) {
            mainAction(GET_LAND_VERSION_ACTION, { id: festId }) 
                .then(resp => {
                    // console.log( resp, festival.version )
                    if(resp !== festival.version) {
                        //clearFestival()
                        loadAndUpdateFestival( festId, setValue )
                    }
                    else {
                        setValue( false ) 
                    }
                })
        }
        else if(
            !lastUpdateFestival || 
            !festival.url || 
            ( festId !== useFestDataStore.getState().festId && !!festId )
        ) {
            //clearFestival()
            loadAndUpdateFestival(festId, setValue)
        }
        else { 
            setValue(false)
        }
    } 
    useEffect(() => getFestival(), [])
    return value 
}

function clearFestival() {
    const festId = "-1"
    useFestDataStore.setState({festId})
    useMainStore.setState({ landId: festId, event_types: [] })
    useFestDataStore.setState({ site: {} as ILand })
    useFestDataStore.getState().setFestival( {} as IFestival )
    useFestDataStore.getState().setTracks( [] )
    useFestDataStore.getState().setHoneycombs( [] )
    useFestDataStore.getState().setGanres( [] )
    useFestDataStore.getState().setMilestones( [] )
    useFestDataStore.getState().setCategories( [] )
    useFestDataStore.getState().setCriteries( [] )
    useDataStore.setState({PEFestProject: []})
}
function loadAndUpdateFestival(festId:ID | null, setValue: (value: boolean) => void ) {
    useFestDataStore.setState({ festId })
    // useMainStore.setState({ landId: festId })
    actions(GET_FESTIVAL_DATA_ACTION, { id: festId })
        .then((response: any) => {
            const f  = response.getPEFestival as IFestival
            const t  = response.getPEFestTracks as ITrack[]
            const d  = response.getPEFestDestricts as IDestrict[]
            const h  = response.getPEFestHoneycombss as IHoneycombs[] 
            const g  = response.getPEFestGanres as IGanre[] 
            const m  = response.getPEFestMilestones as IMilestone[] 
            const c  = response.getPEFestCategorys as ICategory[] 
            const cr = response.getPEFestCriterys as ICritery[]
            const sc = response.getPEFestSchools as ICritery[] 
            const site = response.getLand as ILand
            //console.log( response ) 
            useFestDataStore.getState().setFestival( f )
            useFestDataStore.setState({ site })
            useFestDataStore.getState().setLastUpdateFestival( Date.now() )
            useDataStore.setState({PEFestTrack: t}) 
            useDataStore.setState({PEFestDestrict: d}) 
            useDataStore.setState({PEFestHoneycombs:  h}) 
            useDataStore.setState({PEFestGanre: g}) 
            useDataStore.setState({PEFestMilestone: m}) 
            useDataStore.setState({PEFestCategory: c}) 
            useDataStore.setState({PEFestCritery: cr}) 
            useDataStore.setState({PEFestSchool: sc}) 
            // console.log( useDataStore.getState()  )
            useMainStore.setState({ 
                event_types: site.event_types
            })
        })
        .catch( err => {
            console.error(err)
        })
        .finally(() => {
            useMainStore.setState({ landId: festId })
            setValue(false)
        });
}

export {useFestival, clearFestival}