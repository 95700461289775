import { ID, IMenuItem } from "@/libs/interfaces/layouts"
import { getSingleRoute } from "@/libs/layouts"
import { AppToaster } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import { Intent } from "@blueprintjs/core"
import { useState } from "react"
import { useNavigate, useParams } from "react-router"
import { REGISTER_PROJECT_ACTION } from "../../data/actionTypes"
import { actions } from "../../data/actions"
import EditProjectForm from "./EditProjectForm"

const InsertNewProject = ( ) : JSX.Element => {
    const params = useParams()
    const festId: ID = params.landId || ""
    const [isLoading, setIsLoading] = useState( false ) 
    const [isReadyUpdate, setIsReadyUpdate] = useState( false )
    const [isLocked, setIsLocked] = useState( false )
    const navigate = useNavigate() 
    const onUpdate = ( item: any ) => {
        const data: any = {
            data_type : "PEFestProject",
            item, 
            land_id: festId
        }
        // console.log( data )
        setIsLoading( true )
        setIsLocked( true )
        actions(REGISTER_PROJECT_ACTION, data)
            .then(
                (response) => {
                    const data: any = ( response.data.registerPEFestProject )
                    setIsLoading( false ) 
                    setIsReadyUpdate( false )
                    if(data.message){
                        AppToaster.show({
                            className: "p-2 display-7 text-center ",
                            intent: Intent.SUCCESS,
                            icon: "tick",
                            message: __( data.message ) + " " + __( "Please wait 3 seconds..." ),
                        })
                    }
                    setTimeout( () => {
                        const route:IMenuItem | null = getSingleRoute( "PEFestProject" ) 
                        if( route ){
                            navigate( `/festival/${festId}/${route.route}/${data.id}/ratings/?help&phase=register-project` )
                        }
                    }, 3000 )
                    
                },
                (error) => {
                    console.log(error)
                    setIsLoading(false)
                    setIsReadyUpdate(false)
                    setIsLocked( false ) 
                }
            ) 
    } 
    return <>
        <div className="container display-6 my-5">
            {__( "Fill needed field and click «Send» button:" )}
        </div>
        <EditProjectForm 
            isNew
            isReadyUpdate={isReadyUpdate}
            isLocked={isLocked}
            isLoading={isLoading}
            onUpdate={onUpdate} 
        /> 
    </>
} 

export default InsertNewProject
