import BasicState from "@/libs/basic-view"   
import FestStatisticForm from "./festAdmin/FestStatisticForm"  

class FestStatisticView extends BasicState { 
    addRender = () => {  
        return <> 
            <FestStatisticForm /> 
        </>
    }
}

export default FestStatisticView 