import { __ } from "@/libs/utilities"
import { Button, Icon, Popover } from "@blueprintjs/core"
import { IFestDataStor, IFestStore, useFestDataStore, useFestStore } from "../../data/store"
import { IFestival } from "../../data/interfaces"
import { isRole } from "@/settings/zustand/user"

/* Protopia Ecosystem component */
export interface IVerifiedFilterEngineProps {

}
const VerifiedFilterEngine = (props: IVerifiedFilterEngineProps): JSX.Element => {    
    const festival: IFestival       = useFestDataStore((state:IFestDataStor) => state.festival ) 
    const isVerified: boolean       = useFestStore( (state:IFestStore) => state.isVerified )
    const isNotVerified: boolean    = useFestStore( (state:IFestStore) => state.isNotVerified )
    const setIsVerified = () => {
        useFestStore.setState({isVerified: true, isNotVerified: false})
    }
    const setIsNotVerified = () => {
        useFestStore.setState({isNotVerified: true, isVerified: false})
    }
    const setIsAll = () => {
        useFestStore.setState({isNotVerified: true, isVerified: true})
    }
    
    return !festival.isHideNotVerifiedProjects || isRole(["administrator", "Track_moderator", "School_guardian"]) 
        ?
        <Popover
            interactionKind="hover"
            content={
                <div className='pe-verified-filter-engine-container square p-0'>
                    <Button fill minimal className={isVerified && isNotVerified ? "" :'opacity_5'} onClick={setIsAll}>
                        <div className="lead">
                            {__("all")}
                        </div>
                    </Button>
                    <Button fill minimal className={isVerified ? "" :'opacity_5'} onClick={setIsVerified}>
                        <div className="lead">
                            {__("verified")}
                        </div>
                    </Button>
                    <Button fill minimal className={isNotVerified ? "" :'opacity_5'} onClick={setIsNotVerified}>
                        <div className="lead">
                            {__("not verified")}
                        </div>
                    </Button>
                </div>
            }
        >
            <VerifiedIcon />
        </Popover>
        :
        <></>
    
}
export default VerifiedFilterEngine

const VerifiedIcon = () => {
    const isVerified: boolean     = useFestStore( (state:IFestStore) => state.isVerified )
    const isNotVerified: boolean     = useFestStore( (state:IFestStore) => state.isNotVerified )
    if( isVerified && isNotVerified) {
        return <div className="pointer hover d-flex align-items-center">
            <Icon icon="tick-circle" size={20} className="mx-2" />
            <div className="small">
                {__("all")}
            </div>
        </div>
    }
    else if(isVerified) { 
        return <div className="pointer hover d-flex align-items-center">
            <Icon icon="full-circle" size={20} className="mx-2" />
            <div className="small">
                {__("verified")}
            </div>
        </div>
    }
    else if(isNotVerified) {
        return <div className="pointer hover d-flex align-items-center">
            <Icon icon="cross-circle" size={20} className="mx-2" />
            <div className="small">
                {__("not verified")}
            </div>
        </div> 
    }
}