import { ClipboardEvent } from "react"

/* Protopia Ecosystem component */
export interface IInputTextProps {
   value: string
   placeholder: string
   className: string
   onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
   onPaste: (data: string | null) => void
}
const InputText = (props: IInputTextProps): JSX.Element => {
    const onPaste = ( evt: ClipboardEvent<HTMLInputElement> ) => {
        navigator.clipboard.readText().then( t => {
            props.onPaste(t)
        } ) 
    }
    return <input
        type="text"
        className={props.className}
        value={ props.value }
        placeholder={props.placeholder}
        onChange={ props.onChange }
        onPaste={onPaste}
    />
}
export default InputText