
import Layouts from "."

export function title(): string {
  return Layouts().app?.title || "Protopia Ecosystem"
}

export function default_login() {
  return Layouts().app?.default_login
}

export function init_method() {
  return Layouts().app?.init_method || "static"
}
export function name() {
  return Layouts().app?.name
}

export function description() {
  return Layouts().app?.description
}
export function help_url()
{
  return Layouts().app?.help_url
}

export function externalSystems(): string[] {
  return Layouts().app?.external_systems
}
export function roles() {
  return Layouts().app?.roles
}
// export function adapter() {
//   return Layouts().app?.adapter
// }
export function userModel() {
  return Layouts().app?.user_model
}
//export default Layouts().app;
