import { SCALAR_TYPES } from "@/libs/scalars"

export const attachTypes = () =>
{
    return [
        {
            id: SCALAR_TYPES.URL,
            title:"Link"
        },
        {
            id: SCALAR_TYPES.MEDIA,
            title:"Image"
        },
        // {
        //     id: "youtube",
        //     title:"Youtube video"
        // }, 
        {
            id: SCALAR_TYPES.RUTUBE_ID,
            title:"Rutube video"
        },
        {
            id: SCALAR_TYPES.VK_VIDEO_ID,
            title:"VK video"
        },
        {
            id: SCALAR_TYPES.UPLOAD,
            title:"Upload file (200kB max)"
        },
        // {
        //     id: "geo",
        //     title:"Geo"
        // },
        // {
        //     id: "email",
        //     title:"Email"
        // },
        // {
        //     id: "phone",
        //     title:"phone"
        // },
        {
            id: SCALAR_TYPES.STRING,
            title:"String"
        },
    ]
}