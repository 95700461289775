import { ID } from "@/libs/interfaces/layouts"
import { getQueryArgs } from "src/libs/layouts"
 
export const GET_HONNOR_HALL = ( festId: ID = 0 ) => {
    const site = festId
        ?
        `land_id: "${ festId }"`
        :
        ``
    return `query getHonorHall {
        getPEFestProjects( 
            ${site} 
            paging:{ 
                relation: "OR",
                metas : [
                    {
                        key: "prize",
                        compare: "EXISTS",
                        value: "",
                
                    },          
                    {
                        key: "nomination",
                        compare: "EXISTS",
                
                    } 
                ]
            }
        ) 
        {
            ${ getQueryArgs("PEFestProject") }
        }
    }`
        
} 