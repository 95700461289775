import React from "react"
import "./style.css" 
import String from "./scalars/String"
import IScalarForm from "./interface/IScalarForm"
import Media from "./scalars/Media"
import { SCALAR_TYPES } from './interface/IScalarForm'  
import HTML from "./scalars/HTML"
import DateTime from "./scalars/DateTime"
import Tags from "./scalars/Tags"
import Radio from "./scalars/Radio"
import ImageCheckbox from "./scalars/ImageCheckbox" 
import Checkbox from "./scalars/Checkbox"
import ImageRadio from "./scalars/ImageRadio"
import Boolean from "./scalars/Boolean"
import Number from "./scalars/Number"
import Youtube from "./scalars/Youtube"
import ExtenalSingle from "./scalars/ExtenalSingle"
import Color from "./scalars/Color"
import URL from "./scalars/URL"
import Upload from "./scalars/Upload" 
import Slider from "./scalars/Slider"
import { initArea } from "../utilities"
import Select from "./scalars/Select"
import Geo from "./scalars/Geo"
import CheckboxGroup from "./scalars/CheckboxGroup"
import PostStatus from "./scalars/PostStatus"
import YoutubeList from "./scalars/YoutubeList"
import TimeDuration from "./scalars/TimeDuration"
import ExtenalArray from "./scalars/ExtenalArray"
import Speedometer from "./scalars/Speedometer"
import Percentage from "./scalars/Percentage"
import Depth from "./scalars/Depth" 
import Gallery from "./scalars/Gallery"
import Array from "./scalars/Array"
import Rutube from "./scalars/Rutube"

const FieldInput = (props: IScalarForm ) : React.ReactElement => {
  if (props.visualization) {
    // return <div className="lead p-4">( {props.title}::: {props.visualization} )  </div>
    // console.log(props)
    return initArea(
      props.visualization, 
      { ...props }, 
      <div className="py-3">
        <String 
          {...props} 
          editable={false}
          className={"lead"}
          value={ props.visualization}
        /> 
      </div>
    )
  } 
  switch(props.type)
  {
    case SCALAR_TYPES.CHECKBOX_GROUP:
      return <CheckboxGroup {...props} />
    case SCALAR_TYPES.SELECT:
      return <Select {...props} />
    case SCALAR_TYPES.GEO:
      return <Geo {...props} />
    case SCALAR_TYPES.SLIDER:
      return <Slider {...props} />
    case SCALAR_TYPES.UPLOAD:
      return <Upload {...props} />
    case SCALAR_TYPES.URL:
      return <URL {...props} />
    case SCALAR_TYPES.COLOR:
      return <Color {...props} />
    case SCALAR_TYPES.EXTERNAL:
      return <ExtenalSingle {...props} />
    case SCALAR_TYPES.EXTERNAL_ARRAY:
      return <ExtenalArray {...props} />
    case SCALAR_TYPES.YOUTUBE_ID:
      return <Youtube {...props} />
    case SCALAR_TYPES.RUTUBE_ID:
      return <Rutube {...props} />
    case SCALAR_TYPES.YOUTUBE_LIST:
      return <YoutubeList {...props} />
    case SCALAR_TYPES.NUMBER:
      return <Number {...props} />
    case SCALAR_TYPES.BOOLEAN:
      return <Boolean {...props} />
    case SCALAR_TYPES.CHECKBOX:
      return <Checkbox {...props} />
    case SCALAR_TYPES.IMAGE_CHECKBOX:
      return <ImageCheckbox {...props} />
    case SCALAR_TYPES.RADIO:
      return <Radio {...props} />
    case SCALAR_TYPES.IMAGE_RADIO:
      return <ImageRadio {...props} /> 
    case SCALAR_TYPES.TAGS:
      return <Tags {...props} />
    case SCALAR_TYPES.DATE:
      return <DateTime {...props} />
    case SCALAR_TYPES.HTML:
    case SCALAR_TYPES.TEXT: 
      return <HTML {...props} />
    case SCALAR_TYPES.MEDIA:
      return <Media {...props} />
    case SCALAR_TYPES.GALLERY:
      return <Gallery {...props} />
    case SCALAR_TYPES.POST_STATUS:
      return <PostStatus {...props} />
    case SCALAR_TYPES.TIME_DURATION:
      return <TimeDuration {...props} />
    case SCALAR_TYPES.SPEEDOMETER:
      return <Speedometer {...props} />
    case SCALAR_TYPES.PERCENTAGE:
      return <Percentage {...props} />
    case SCALAR_TYPES.DEPTH:
      return <Depth {...props} />
    case SCALAR_TYPES.ARRAY:
      return <Array {...props} />
    case SCALAR_TYPES.STRING:
    default:
      return <String {...props} />
  }
} 
export default FieldInput 
export { SCALAR_TYPES } 