import React, { useEffect, useId, useState } from 'react' 
import { Dialog, Icon, Intent } from '@blueprintjs/core'
import { __ } from "src/libs/utilities"
import IScalarForm from '../interface/IScalarForm'
import Scalar from './Scalar'
import useScreenSize from '@/modules/pe-basic-module/hooks/useScreenSize'

const Youtube = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<YoutubeEnabled {...props} />}
        desabledForm={<YoutubeDesabled {...props} />}
    />
} 
export default Youtube 

export const YoutubeEnabled = ( props: IScalarForm ): JSX.Element => { 
    const onChange = (evt: any) => {
        const value: string = evt.target.value
        const myregexp: RegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
        const v = value.match( myregexp )
        if( props.onChange )
        {
            props.onChange( (v && v[7]?.length === 11) ? v[7] : '' ,  props.field, "" )
        }   
    }

    return <div className=" d-flex flex-column  w-100">
        {/* <iframe
            width={props.width ? props.width : props.height && typeof props.height === "number" ? props.height * 1.6 : "320"} 
            height={props.height || "160"} 
            src={`https://www.youtube.com/embed/${  props.value }`} 
            title="YouTube video player"
             allowFullScreen
        /> */}
        <YoutubePlayer 
            { ...props }
            height={ props.height || 160}  
        /> 
        <input  
            className = "form-control input dark" 
            value = {  props.value } 
            onChange={onChange}
        /> 
    </div>
} 
 
export const YoutubeDesabled = ( props: IScalarForm ): JSX.Element => {
    // return props.value
    //     ?
    //     <div className=" d-flex flex-column  w-100">
    //         <iframe 
    //             width={props.width ? props.width : props.height && typeof props.height === "number" ? props.height * 1.6 : "320"} 
    //             height={props.height || "160"} 
    //             src={`https://www.youtube.com/embed/${  props.value }`} 
    //             title="YouTube video player"
    //             allowFullScreen
    //         /> 
    //     </div>
    //     :
    //     props.value
    return <YoutubePlayer {...props} />
}

export const YoutubePlayer = (props: any): JSX.Element => {
    const id = useId()
    const [isPlay, setIsPlay]   = useState<boolean>(false)
    const [width, setWidth]     = useState<number>(props.width  || 400)
    const [height, setHeight]   = useState<number>(props.height || 160)
    const windowSize = useScreenSize()
    useEffect(() => {
        const ytp: HTMLElement = document.getElementById(`yutube-player-${id}`)!
        setWidth(ytp.offsetWidth)
        setHeight(ytp.offsetHeight)
    }, [])
    const onPlay = () => {
        setIsPlay(!isPlay)
    }
    return <div style={{width, height}} id={`yutube-player-${id}`}>
    {
        !isPlay
            ?
            <Thumbnail id={ props.value } width={width} height={height} onPlay={onPlay} />
            :
            width  > 400 && 
            height > 160
                ?
                <iframe 
                    width={props.width ? props.width : props.height && typeof props.height === "number" ? props.height * 1.6 : "320"} 
                    height={props.height || "160"} 
                    src={`https://www.youtube.com/embed/${  props.value }?controls=2&autoplay=1`} 
                    title="YouTube video player"
                    allowFullScreen
                />
                :
                <>
                    <Thumbnail id={ props.value } width={props.width} height={props.height} onPlay={onPlay} />
                    <Dialog
                        isOpen={true}
                        onClose={() => setIsPlay(false)}
                    >
                        <iframe
                            width={windowSize.width < 800 ? windowSize.width : 800 } 
                            height={480} 
                            src={`https://www.youtube.com/embed/${  props.value }?controls=2&autoplay=1`} 
                            title="YouTube video player"
                            allowFullScreen
                        />
                    </Dialog>
                </>
    }        
    </div>
} 

export const Thumbnail = (props: any) :JSX.Element => {
    return <div 
        className={`video-container position-relative ${props.isPlay ? "isPlay" : ""}`}  
        style={{width:props.width, height: props.height}}
        onClick={props.onPlay}
    >  
        <div 
            className={`thumbnail card-top-img1 `} 
            style={{
                backgroundImage:`url(https://img.youtube.com/vi/${ props.id }/0.jpg)`, 
                width:props.width, 
                height: props.height
                }}  
            onClick={props.onPlay}
        /> 
        <svg 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="video-icon" 
        >
            <path className="round" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="#00000077" stroke="#FFFFFF" strokeWidth=".33" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="tre" d="M15.0015 11.3344C15.3354 11.5569 15.5023 11.6682 15.5605 11.8085C15.6113 11.9311 15.6113 12.0689 15.5605 12.1915C15.5023 12.3318 15.3354 12.4431 15.0015 12.6656L11.2438 15.1708C10.8397 15.4402 10.6377 15.5749 10.4702 15.5649C10.3243 15.5561 10.1894 15.484 10.1012 15.3674C10 15.2336 10 14.9908 10 14.5052V9.49481C10 9.00923 10 8.76644 10.1012 8.63261C10.1894 8.51601 10.3243 8.44386 10.4702 8.43515C10.6377 8.42515 10.8397 8.55982 11.2438 8.82917L15.0015 11.3344Z" stroke="#FFFFFF" strokeWidth=".33" strokeLinecap="round" strokeLinejoin="round"/>
        </svg> 
    </div>
  } 
 