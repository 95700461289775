import FieldInput from "@/libs/scalars";
import { SCALAR_TYPES, VerticalType } from "@/libs/scalars/interface/IScalarForm"; 

/* Protopia Ecosystem component */
export interface IAttachInputProps {
    att: any
}
const AttachInput = (props: any): JSX.Element =>
{ 
    const onUploadChange = ( value: any, field: string ) => {
        // console.log( props.att)
        props.onChange(value, field, props.att.type)
    }
    let input: JSX.Element
    switch( props.att.type )
    {
        case "youtube": 
            input = <FieldInput
                vertical={VerticalType.VERTICAL} 
                height= { 100 }
                width = { 150 }
                value = { props.att.data }
                field = { props.att.descr } 
                editable = { props.editable }
                type = { SCALAR_TYPES.YOUTUBE_ID }
                on = { props.onChange }
                onChange = { props.onChange }
                notClear
            />              
            break;
        case SCALAR_TYPES.RUTUBE_ID: 
            input = <FieldInput
                vertical={VerticalType.VERTICAL} 
                height= { 100 }
                width = { 150 }
                value = { props.att.data }
                field = { props.att.descr } 
                editable = { props.editable }
                type = { props.att.type }
                on = { props.onChange }
                onChange = { props.onChange }
                notClear
            />              
            break;
        case "upload":
            input = <FieldInput
                vertical={VerticalType.VERTICAL}  
                height={ 150 }
                width={ 300 }
                value={ props.att.data }
                field={ props.att.descr }
                values={ [] }
                accept={"text/plain,text/html,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/zip,application/x-rar-compressed,application/x-7z-compressed,application/pdf"}
                editable={ props.editable }
                type={ SCALAR_TYPES.UPLOAD }
                on={ onUploadChange }
                onChange={ onUploadChange } 
                max={ 100 }
                notClear
                placeholder="Upload file and click «Add» button"
            /> 
             
            break;
        default:
            input = <FieldInput
                vertical={VerticalType.VERTICAL} 
                height={ 150 }
                value={ props.att.data }
                field={ props.att.descr }
                values={ [] }
                accept={"image/*"}
                editable={ props.editable }
                type={ props.att.type }
                on={ props.onChange }
                onChange={ props.onChange }
                notClear
            /> 
            break
    }
    return <>
        {input} 
    </>
}
export default AttachInput