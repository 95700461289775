import { Button, Intent } from "@blueprintjs/core"
import { IProject } from "../../data/interfaces"
import { __ } from "@/libs/utilities"
import { ID } from "@/libs/interfaces/layouts"

/* Protopia Ecosystem component */
export interface IMemberAllowFormProps {
    item: IProject
    onChangeVerify: (id: ID, is_verified: boolean) => void
}
const MemberAllowForm = ({item, onChangeVerify, ...props}: IMemberAllowFormProps): JSX.Element => {
    return item.is_verified 
        ?
        <Button 
            minimal 
            fill 
            intent={Intent.SUCCESS} 
            rightIcon="cross" 
            className="px-5"
            onClick={() => onChangeVerify(item.id, false)}
        >
            <div className="text-nowrap">
                {__("Do revoke")} 
            </div>
        </Button>
        :
        <Button 
            minimal 
            fill 
            intent={Intent.DANGER} 
            rightIcon="tick" 
            className="px-5"
            onClick={() => onChangeVerify(item.id, true)}
        >	
            <div className="text-nowrap">
                {__("Do approve")}
            </div>
        </Button>
}
export default MemberAllowForm