import { IUser } from "src/settings/zustand" 
import { useMainStore } from "src/settings/zustand"
import { IDestrict, IGanre, IHoneycombs, IPEFestSchool, IProject, ITrack } from "./interfaces"
import { ID } from "@/libs/interfaces/layouts"
import { useFestMyProjects } from "./store"
import { isRole } from "@/settings/zustand/user"

function isOwnerTrack( track: ITrack): boolean {
    if(!isRole("Track_moderator")) return false
    const user: IUser = useMainStore.getState().user
    return user.id === track?.moderator?.id || user.id === track?.moderator2?.id 
}

function isMyTrack (track:ITrack) {
    const myProjects: IProject[] = useFestMyProjects.getState().myProjects
    return myProjects.some(( p:IProject ) => {
        return p.track?.id === track.id
    })
}
function isMyDestrict (track: IDestrict) {
    const myProjects: IProject[] = useFestMyProjects.getState().myProjects
    return myProjects.some(( p:IProject ) => {
        return p.destrict?.id === track.id
    })
}
function isMySchool (track: IPEFestSchool) {
    const myProjects: IProject[] = useFestMyProjects.getState().myProjects
    return myProjects.some(( p:IProject ) => {
        return p.fmru_school.filter(s => s.id === track.id)
    })
}


function isMyHoneycombs ( honeycombs:IHoneycombs ) {
    const myProjects: IProject[] = useFestMyProjects.getState().myProjects
    return myProjects.some(( p:IProject ) => {
        return p.honeycombs?.id === honeycombs.id
    })
}

function isOwnerHoneycombs( honeycombs: ID): boolean {
    const user: IUser = useMainStore.getState().user
    //return user.id === honeycombs.id
    return true
}

function isOwnerGanre( ganre: IGanre): boolean {
    const user: IUser = useMainStore.getState().user
    return user.id === ganre.id
}

export { isOwnerTrack, isOwnerHoneycombs, isOwnerGanre, isMyTrack, isMyDestrict, isMySchool, isMyHoneycombs }