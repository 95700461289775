import React, { useEffect, useState } from 'react' 
import { IFestival, ISettingsPageFormProps } from '../../../pe-fest-module/data/interfaces'
import { Card } from '@blueprintjs/core' 
import {__} from 'src/libs/utilities'
import { Loading } from 'src/libs/useful'
import FieldInput from 'src/libs/scalars'    
import { actions } from '../../../pe-fest-module/data/actions'
import { GET_FESTIVAL_COMMUNICATION_ACTION } from '../../../pe-fest-module/data/actionTypes'
import { SCALAR_TYPES, VerticalType } from 'src/libs/scalars/interface/IScalarForm'
import { useAppoloStore } from 'src/settings/zustand'
import gql from 'graphql-tag'
import { useParams } from 'react-router'

const CommunicationsForm = (props: ISettingsPageFormProps) : JSX.Element => {
    const params = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [festival, setFestival] = useState<IFestival>({} as IFestival) 
    const [defaultFest, setDefaultFest] = useState<string>("") 
    useEffect(() => {
        actions(GET_FESTIVAL_COMMUNICATION_ACTION, { land_id: params.landId } )
            .then(
                response => {
                    setFestival(response)
                    setIsLoading(false)
                    setDefaultFest(JSON.stringify( response ))
                }
            )
        
    },[])
    if( isLoading) return <Loading />

    const onValueChange = (field: keyof IFestival, value: any) =>
    { 
        setFestival( {
            ...festival,
            [field]: "<p>" + value.replace(/\n/gi, "</p><p>") + "</p>"
        } ) 
    }
    const updateFestival = () => {
        console.log( festival )
        const apolloClient: any = useAppoloStore.getState().client
        apolloClient.mutate({
            mutation : gql`
            mutation changeFestival {
                changePEFestival (
                    input: { 
                        newProjectEmail: "${ festival.newProjectEmail }"
                        newProjectMessage: "${ festival.newProjectMessage }" 
                    }
                ) {
                    newProjectEmail
                    newProjectMessage    
                }
            }`
        })
            .then( ( response: any ) => {
                console.log( response.data.changePEFestival ) 
                setFestival({
                    ...festival,
                    ...response.data.changePEFestival 
                })
                setDefaultFest(JSON.stringify( {
                    ...festival,
                    ...response.data.changePEFestival 
                }))
            })
    }
    return (
        <div className='mb-5'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='display-5 mb-3'>{ __(props.title) }</div> 
                <div 
                    onClick={updateFestival}
                    className={`btn btn-${defaultFest !== JSON.stringify(festival) ? 'danger' : 'outline-secondary untouchble'}`}
                >
                    {__("Update Festival data")}   
                </div>
            </div>
            <div className='row'>
                <div className='col-12 '> 
                <Card className="p-4 mb-2" data-element="status">
                    <div className="lead mb-1">{ __("Email text for new Project registration") }</div>
                    <FieldInput 					
                        vertical={VerticalType.VERTICAL}
                        editable
                        value={
                            festival.newProjectEmail   
                            || 
                            "--"
                        }
                        commentary={__('Set description')}
                        type={SCALAR_TYPES.TEXT}  
                        onChange={ (value:any) => onValueChange("newProjectEmail", value) }
                    />
                </Card>
                <Card className="p-4 mb-2" data-element="status">
                    <div className="lead mb-1">{ __("Modal message after new Project registration") }</div>
                    <FieldInput 					
                        vertical={VerticalType.VERTICAL} 
                        editable
                        value={
                            festival.newProjectMessage   
                            || 
                            "--"
                        }
                        commentary={__('Set description')}
                        type={SCALAR_TYPES.TEXT}  
                        onChange={ (value:any) => onValueChange("newProjectMessage", value) }
                    />
                </Card>
                </div> 
            </div> 
        </div> 
    )
} 

export default CommunicationsForm