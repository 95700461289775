import { __, } from "@/libs/utilities"
import { IUser } from "@/settings/zustand"
import { Button, Collapse } from "@blueprintjs/core"
import { FormEvent, ReactElement, useState } from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { default_login, getAllRoutes } from "src/libs/layouts"
import actions from '../../data/actions'
import { MUTATION_EXTERNAL_TOKEN_ACTION, MUTATION_TOKEN_ACTION } from "../../data/actionTypes"
import ExternalLogins from "./externals"

const LoginForm = (props: any): ReactElement<any, any> => {
    const [showPass, setShowPass] = useState(false)
    const [isErrorOpen, setIsErrorOpen] = useState(false)
    const [login, setLogin] = useState("") 
    const [password, setPassword] = useState("")
    const [isLoggedIn, changeLoggedIn] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()  
    const onResponse = (response: any) => {
        //console.log( response )
        changeLoggedIn(false)
        if(props.onResult) {
            props.onResult(props.onResultProps)
        }
        else if( location.state?.pathname ) 
        {
            // редирект со страницы, которая редиректнула сама ( ей нужен юзер для доступа )
            navigate(location.state.pathname)
        }
        else { 
            // при условии - переходим в Кабинет Редактора, иначе -- на главную
            const routes = getAllRoutes().filter( route => route.component === "EditorCabinet" )
            const usl: boolean = typeof routes[0] !== "undefined" && 
                response.roles
                .filter( (role: string) => role === "administrator" || role === "editor" )
                    .length > 0
            navigate(
                usl 
                    ? 
                    `/${routes[0].route}` 
                    : 
                    '/' 
            )
        }
    }
    const onCallBack = ( user: IUser, external: string, label:string ) => {
        console.log( user, external, label )
        actions( MUTATION_EXTERNAL_TOKEN_ACTION, {user, external})
        .then(
            (response: any) => {
                console.log( response )
                onResponse( response )
            },
            ((error) => {
                console.log( error )
                changeLoggedIn(false)
            })
        )
    } 
    const onLogin = ( evt: FormEvent ): void => {
        evt.preventDefault()
        evt.stopPropagation()
        changeLoggedIn(true)
        actions( MUTATION_TOKEN_ACTION, {login, password} )!
            .then(
                (response: any) => {
                    onResponse( response )
                },
                ((error) => {
                    console.log( error )
                })
            )
            .catch((error: any) => {
                console.log( error ) 
            })
    }
    return <div className="d-flex h-100  col-12  flex-column flex-centered">
        <div className="display-6 p-3 text-center ">
            {__("Sign in")}
        </div>
        <div className="flex-centered py-4 flex-wrap">
            <ExternalLogins onCallBack={onCallBack} />
        </div> 
        <Collapse isOpen={isErrorOpen}>
            
        </Collapse> 
        {
            default_login() === "login"
                && 
                <form 
                    onSubmit={ onLogin }
                    className=" flex-centered flex-column p-md-5 px-3 py-4 mt-auto"
                >
                    <div className="form-group w-100">   
                        <input
                            type="text"
                            className="form-control input dark"
                            placeholder={__("Email")}
                            onChange={ evt => setLogin( evt.target.value )}
                            value={ login }
                        />
                    </div>
                    <div className="form-group w-100 position-relative">
                        <input
                            type={ showPass ? "text" : "password" }
                            className="form-control input dark"
                            placeholder={__("Password")}
                            onChange={evt => setPassword( evt.target.value )}
                            value={password}
                        />
                        <Button 
                            minimal 
                            style={{
                                position: "absolute",
                                top: 4,
                                right: 3,
                            }}
                            icon={ showPass ? "eye-open" : "eye-off" }
                            onMouseDown={ () => setShowPass(true) }
                            onMouseUp={ () => setShowPass(false) }
                        />
                    </div>
                    <div className="form-group form-check hidden">
                        <label
                            className="form-check-label text-left"
                            htmlFor="exampleCheck1"
                        >
                            {__("If you do not have a profile, then you can: ")}
                            <NavLink
                                to="/register"
                                className="text-center"
                            >
                                {__("Register")}
                            </NavLink>
                        </label>
                    </div>
                    <div className="form-group form-check">
                        <label
                            className="form-check-label text-left"
                            htmlFor="exampleCheck1"
                        >
                            {__("If you have forgotten your password: ")}
                            <NavLink
                                to="/remember"
                                className="text-center"
                            >
                                {__("Remind password")}
                            </NavLink>
                        </label>
                    </div>
                    <div className="btn btn-primary py-2 px-5 rounded-pill" style={{width:170}} onClick={ onLogin }>
                        { !isLoggedIn ? __("Sign in") : <i className='fas fa-sync-alt fa-spin' /> } 
                    </div>
                </form>
        }
        
    </div>
}

export default LoginForm

// class LoginForm extends Component
// {
//     constructor(props)
//     {
//         super(props)
//         this.state = {
//             login:"", 
//             password:"", 
//             showPass:false,
//             isFlip:false
//         }
//     }

//     login = e => this.setState({ login: e.currentTarget.value }) 
//     passWord = e =>  this.setState({ password: e.currentTarget.value }) 
//     showPass = e => this.setState({showPass:true})
//     hidePass = e => this.setState({showPass:false})
//     onVK = () =>
//     {

//     }
//     responseVk = () =>
//     {
        
//     }
//     onYandex = () =>
//     {

//     } 
//     render()
//     { 
//         return this.loginForm( ) 
//     }
//     loginForm = ( ) =>
//     {
//         return <div className="d-flex h-100 flex-column">
//             <div className="flex-centered pt-4">
//                 <VKLogin
//                     apiId={ config?.vk_api_key }
//                     value={"VK"}
//                     fields="name,email,picture"
//                     onClick={ this.onVK }
//                     callback={ this.responseVk} 
//                     className="wpfest-admin-cs mr-1"
//                 />
//                 <div className=" wpfest-admin-cs -hidden mr-1"> 
//                     <div className="soc yandex wpfest-admin-cs " onClick={ this.onYandex }>
//                         <i className="fab fa-yandex" />
//                     </div> 
//                 </div>
//                 <div className=" wpfest-admin-cs -hidden mr-1"> 
//                     <div className="soc telegram wpfest-admin-cs " onClick={ this.onYandex }>
//                         <i className="fab fa-telegram-plane" />
//                     </div> 
//                 </div>
//                 <div className=" wpfest-admin-cs -hidden mr-1"> 
//                     <div className="soc odnoklassniki wpfest-admin-cs " onClick={ this.onYandex }>
//                         <i className="fab fa-odnoklassniki" />
//                     </div> 
//                 </div>
//             </div>
//             <form 
//                 onSubmit={(evt) => this.onLogin(evt, token, context)}
//                 className=" flex-centered flex-column p-md-5 px-3 py-4 mt-auto"
//             >
//                 <div className="form-group w-100">   
//                     <input
//                         type="text"
//                         className="form-control input dark"
//                         placeholder={__("Email")}
//                         onChange={this.login}
//                     />
//                 </div>
//                 <div className="form-group w-100 position-relative">
//                     <input
//                         type={ this.state.showPass ? "text" : "password" }
//                         className="form-control input dark"
//                         placeholder={__("Password")}
//                         onChange={this.passWord}
//                     />
//                     <Button 
//                         minimal 
//                         style={{
//                             position: "absolute",
//                             top: 4,
//                             right: 3,
//                         }}
//                         icon={ this.state.showPass ? "eye-open" : "eye-off" }
//                         onMouseDown={this.showPass}
//                         onMouseUp={this.hidePass}
//                     />
//                 </div>
//                 <div className="form-group form-check hidden">
//                     <label
//                         className="form-check-label text-left"
//                         htmlFor="exampleCheck1"
//                     >
//                         {__("If you do not have a profile, then you can: ")}
//                         <NavLink
//                         to="/register"
//                         className="text-center"
//                         >
//                         {__("Register")}
//                         </NavLink>
//                     </label>
//                 </div>
//                 <div className="form-group form-check">
//                     <label
//                         className="form-check-label text-left"
//                         htmlFor="exampleCheck1"
//                     >
//                         {__("If you have forgotten your password: ")}
//                         <NavLink
//                         to="/remember"
//                         className="text-center"
//                         >
//                             {__("Remind password")}
//                         </NavLink>
//                     </label>
//                 </div>
//                 <input 
//                     type="submit" 
//                     className="btn btn-primary py-2 px-5 rounded-pill" 
//                     value={__("Sign in")} 
//                 />
//             </form>
//         </div>
//     }
//     onLogin = (evt, token, context) => 
//     {
//         evt.preventDefault()
//         token({
//             variables:
//             {
//                 input: {
//                     grant_type: "client_credentials",
//                     login: this.state.login || "",
//                     password: this.state.password || "",
//                 },
//             },
//             update: (store, { data }) => {
//                 //console.log(data)
//                 if (data.token) 
//                 {
//                     //console.log(data)
//                     AppToaster.show({
//                         intent: Intent.SUCCESS,
//                         icon: "tick",
//                         message: __("You enter by User"),
//                     })
//                     localStorage.setItem(
//                         "token",
//                         data.token.access_token,
//                     )
//                     // context.setUser( queryUserInfo() );
//                     // this.props.history.replace("/")
//                     this.props.client.query({ query: queryUserInfo(), fetchPolicy: 'network-only' })
//                         .then(
//                             result => {
//                                 //console.log(result)
//                                 context.setUser(result.data.userInfo);
//                                 if(this.props.onResult)
//                                 {
//                                     this.props.onResult( this.props.onResultArgs )
//                                 }
//                                 else
//                                 {
//                                     // при условии - переходим в Кабинет Редактора
//                                     const routes = getAllRoutes().filter( route => route.component === "EditorCabinet" )
//                                     const usl = typeof routes[0] !== "undefined" && 
//                                         result.data.userInfo.roles
//                                             .filter( role => role === "administrator" || role === "editor" )
//                                                 .length > 0
                                    
//                                     this.props.history.replace( usl ? routes[0].route : '/' )
//                                 }                        
//                             },
//                         );

//                 } 
//                 else 
//                 {
//                     AppToaster.show({
//                         intent: Intent.DANGER,
//                         icon: "tick",
//                         message: __("You not enter by User"),
//                     })
//                 }
//             },
//             refetchQueries: [{ query: queryUserInfo(), variables: {} }],
//         })
//     }
// }

// export default compose(
//     withApollo,
//     withRouter,
//   )( LoginForm )