import { ID, WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import { LayoutIcon } from '@/libs/useful'
import { __, compareArrays } from '@/libs/utilities'
import { useDataStore } from '@/modules/pe-basic-module/data/store'
import { Button, ButtonGroup, Collapse, Popover, Position } from '@blueprintjs/core'
import React, { useEffect, useRef, useState } from 'react'
import { template } from 'src/libs/layouts'
import { isRole } from '../../../settings/zustand/user'
import { SET_FILTERS_ACTIONS } from '../data/actionTypes'
import { syncAction } from '../data/actions'
import { IFestFilters, IHoneycombs } from '../data/interfaces'
import { IFestStore, useFestStore } from '../data/store'
import { isMyHoneycombs } from '../data/user'
import useClickOutside from './hooks/useClickOutside'
import useProjectListRoute from './hooks/useProjectListRoute'

const HONEYCOMBS_FILTER_ICON_URL = "/assets/img/fest/honeycombs.svg"

const HoneycomsFilterMenu = (props: any) :JSX.Element => {
    if(window.innerWidth < WINDOW_WIDTH.TABLET) return <></> 
    return <HoneycomsFilter />   
}
export const HoneycomsFilter = ( props: any ) :JSX.Element => {
    const [title, setTitle] = useState( __( "all Honeycombs" ) )
    let timeOut: any = -1
    const [isOpen, setOpen] = useState(false)
    const [isCollapse, setCollapse] = useState(false)   
    
    const filters: IFestFilters = useFestStore((state: IFestStore) => state.filters )
    const honeycombs : IHoneycombs[] = useDataStore((state:any) => state.PEFestHoneycombs)  || []
    const myRef: React.MutableRefObject<null> = useRef( null )
    const setCurrentTitle = () => {
        switch(filters.honeycombs) {
            case null:
            case [ ]:
            case [-1]:
                setTitle( "all Honeycombs" )
                break
            case ["mine"]:
                setTitle( "Only mine Honeycombs" )
                break
            default:
                const curTrack = honeycombs?.filter( honeycomb => honeycomb.id === filters.honeycombs[0] )[0]
                setTitle( curTrack ? curTrack.title : "all Honeycombs")
        }
    }
    const onOpen = (boo : boolean) =>
    {
        setOpen(boo)
    }
    const handleLoginOpen = () =>
    {
        clearTimeout( timeOut )
        onOpen(true)
    }
    const onMouseLeave = () =>
    {
        clearTimeout( timeOut )
        timeOut = setTimeout( () =>
        {
            onOpen(false)
        }, 1000 )
    } 

    const isRoute: boolean = useProjectListRoute() && !!honeycombs.length
    useClickOutside( myRef, onOpen, timeOut)

    useEffect( () => {
        setCurrentTitle()
    }, [ filters ] )

    const selectHoneycombs = (id:ID) => { 
        const __honeycombs = id === -1
            ?
            [-1]
            :
            id === "mine"
                ?
                honeycombs.filter( ( _honeycombs: IHoneycombs ) => {
                    return isMyHoneycombs( _honeycombs )
                })
                .map(_honeycombs => _honeycombs.id)
                :
                [ id ]
        syncAction(SET_FILTERS_ACTIONS, { filters:{ honeycombs: __honeycombs, schools: filters.schools } } )
        setCurrentTitle()
        clearTimeout( timeOut )
        onOpen(false)
    }
    return <>   
        <div
            className={`indicator dble lrg classic p-0 m-0 overflow-hidden ${ isRoute ? "" : " closed"}`}            
            onMouseLeave={onMouseLeave}
            ref={ myRef }
        >
            <div className={ `header-menu-element  ${ template().header_menu } main-menu-filter-icon ` }  onClick={handleLoginOpen}>
                <LayoutIcon
                    src={ HONEYCOMBS_FILTER_ICON_URL }
                    className="header-menu-icon"
                    isSVG={false}
                />
                <span className='header-menu-label'>
                    { __( title ) }
                </span>
            </div>
            <Popover
              className="indicator-popover"
              popoverClassName="p-0" 
              position={ Position.BOTTOM_RIGHT }
              isOpen={isOpen}
              content={(
                <div className='overflow-y-auto' style={{maxHeight:"calc(100vh - 150px)"}}>
                    <ButtonGroup
                        vertical 
                        fill 
                        minimal 
                        onMouseEnter={() =>  clearTimeout( timeOut ) }
                        style={{ minWidth: 200 }}
                    >
                        {
                            true //filters.honeycombs[0] !== -1 || filters.tracks[0] !== -1 
                                ?
                                <Button 
                                    className={`p-3 btn-stretch text-force-contrast ${ compareArrays( [ -1 ], filters.honeycombs ) ? " bg-danger  text-light " : "" }`} 
                                    onClick={() => selectHoneycombs( -1 ) }>
                                    {__("all Honeycombs")}
                                </Button>
                                :
                                null
                        }                      
                        {
                            isRole([ "Tutor", "Project_author", "Track_moderator" ]) 
                                ?   
                                <Button
                                    className={`p-3 btn-stretch text-force-contrast ${ compareArrays( ['mine'], filters.honeycombs ) ? " bg-danger  text-light " : "" }`}  
                                    onClick={() => selectHoneycombs( "mine" ) }>
                                    {__("Only mine Honeycombs")}
                                </Button> 
                                :
                                null
                        }
                        {
                        Array.isArray( honeycombs )
                            ?
                            <>
                                <Button 
                                    icon="chevron-down" 
                                    minimal
                                    small
                                    className={ isCollapse ? "hidden" : " p-3 "} 
                                    onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                                        setCollapse(true)
                                        onOpen(true)
                                        event.preventDefault()
                                        event.stopPropagation()
                                    }}
                                />
                                <Collapse isOpen={ isCollapse } >
                                    <div className="d-flex flex-column">
                                    {
                                        honeycombs.map((honeycombs: IHoneycombs) =>
                                            {
                                                return <Button 
                                                    className={ `p-0 btn-stretch text-force-contrast  ${ filters.honeycombs?.includes( honeycombs.id ) ? " bg-danger text-light " : "" }` } 
                                                    key={ honeycombs.id } 
                                                    onClick={ () => selectHoneycombs( honeycombs.id ) }
                                                > 
                                                    <div className='d-flex'>
                                                        <span className='flex-grow-100 p-3 small '>{ honeycombs.title }</span>
                                                        <span className ='p-3 bg-secondary-super-light small '>{ honeycombs.count || 0 }</span>
                                                    </div>
                                                </Button>
                                                    
                                            })
                                    }                                    
                                    </div>
                                </Collapse>
                            </>
                            :                        
                            <div className='p-3' >
                                {__("no Honeycombs")}
                            </div>   
                        }
                    </ButtonGroup>
                </div>
              )}
            >
                <div style={{ marginTop: 0 }} />
            </Popover>
        </div>
    </>
}
 

export default HoneycomsFilterMenu