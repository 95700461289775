import { Position } from "@blueprintjs/core"
import { IUser } from "src/settings/zustand"
import { SCALAR_TYPES } from "../scalars" 


export interface IDictionary {
    [key: string] : string
  }
export interface ILayoutData {
    template:           any
    app:                IAppData
    folders:            any
    widgets:            {}
    extentions:         {}
    views:              any
    ['widget-area']:    {}
    routing:            IRoutingData
    modules:            any
    schema:             any
}
export interface IAppData {
    title: string | null
    name: string | null
    description: string | null
    external_systems: EXTERNAL_SYSTEM[]
    scopes: string | null
    adapter: string | null
    user_model: string | null
    help_url: string | null
    init_method: string | null
    roles: string[]
    email: string | null
    adress: string | null
    user_verify_account: string | null
    vk: string | null
    youtube: string | null
    android: string | null
    apple: string | null
    vk_app_id: string | null 
    default_login: "login" | "external"
}
export enum EXTERNAL_SYSTEM {
    vk="vk",
    yandex="yandex",
    telegram="telegram",
}
export interface IRoutingData {  
    [partName: string]: any  
    extended_routes:    any
    menu:               any
    main_menu:          any
    footer:             any
    profile:            any            
    link:               any 
}
export enum LayoutsTags {
    template = "template",
    folders = "folders",
    widgetArea = "widget-area",
    routing = "routing",
    modules = "modules",
    schema = "schema"
}

export type ID = string | number
export type Date = string | number
export type URL = string
export type Icon = URL | string
export type Role = string
export type HTML = string
export type Json = string
export type Color = string
export type Email = string
export type Phone = string

export interface IMedia extends IPost {

}

export interface ILayoutBodyProps {
    [partName: string]: any  
    current?: number
    onCurrent?: () => {}
    onChangeStyle?: () => {}
}

export enum APOLLO_FIELD_FILTER_TYPE {
    TAXONOMIES="taxonomies",
    META="meta"
}

export interface HelpYoutube {
    url: URL
    title: string
    description? : string
}

export interface IMenuItem {
    id: ID 
    children? : any[]
    html: string
    html_source: string
    component: string
    route : string
    parent?: string
    route_matrix?: string[]
    route_filters?:any
    realRoute  : string
    icon : string
    title: string
    description : string
    singled_data_type: string
    feed_data_type: string 
    single_data_type: string
    data_type : string
    i: number
    is_hidden: boolean
    current: number
    parent_route: any
    extend_params?: any | null
    level: number 
    folder: number
    hide_title: boolean
    is_cabinet: boolean
    menu: string
    module: string
    thumbnail: URL
    islogged: boolean
    capability: Role[]
    redirect?: any
    is_left: boolean
    target_id?: string 
    aside? : any  
    help_url? : URL
    helps?: HelpYoutube[]
    childRoutes?: string[]
}
export const MENU_VIEW_TYPES: any  = {
    HTML: {
        _id:    "html",
        title:  "Simple editible html-content"
    }, 
    COMPONENT: {
        _id:    "component",
        title:  "Special component"
    },
    HTML_SOURCE: {
        _id:    "html_source",
        title:  "Outer html sourse by URL"
    },
    SINGLED_DATA_TYPE: {
        _id:    "singled_data_type",
        title:  "Visualizator of single item by Data Type"
    },
    FEED_DATA_TYPE: {
        _id:    "feed_data_type",
        title:  "Feed of items by Data Type"
    },
    SINGLE_DATA_TYPE: {
        _id:    "single_data_type",
        title:  "Edit form of item by Data Type for administrator's panel"
    },
    DATA_TYPE: {
        _id:    "data_type",
        title:  "Feed of items by Data Type for admistrators panel"
    },
}
export type IGeoPosition = {
    [partName: string]: any
}
export interface IImageElement {
    width: number
    height: number
    result: any
}

export enum POST_STATUS {
    PUBLISH ="publish",
    TRASH = "trash",
    DRAFT = "draft",
    FUTURE="future",
    PENDING="pending"
}

export interface IPost {
    id: ID
    ID?: ID
    title: string
    post_content: HTML
    thumbnail?: URL
    thumbnail_id?: ID
    thumbnail_name?: string
    post_author? : IUser
    post_status?: POST_STATUS
    post_date?: string
    likes?: ILike[]
    comments?: IComment[]
    __typename?: string
}
export interface ITax extends IPost {
    order: number
}
export interface IComment {
    id:ID
    discussion_id: ID
    discussion_type: string
    discussion: IPost
    content: HTML
    parent?: IComment
    author: IUser
    title? : string
}
export interface ILike {
    id: ID 
    dataType: string
    postID: ID
    user?: IUser
    type: LIKE_TYPE 
}

export enum LIKE_TYPE {
    LIKE="LIKE",
    FIRE="FIRE",
    CLOWN="CLOWN",
    UP="UP",
    DOWN="DOWN",
    ANGRY="ANGRY"
}
export interface IPEMessage extends IPost {
    resource_title?: string
    resource_type?: string
    resource_id?: ID
    is_viewed: boolean

}

export enum COLOR_TYPE {
    PLAIN="PLAIN",
    GRADIENT="GRADIENT"
}


export enum WINDOW_WIDTH {
    PANEL   = 1400,
    LARGE   = 1200,
    SCREEN  = 992,
    TABLET  = 768,
    MOBILE  = 540,
    SMALL   = 360
}

export interface VK_URL {
    url : URL
    title: string
    site_name: string
    description: string
    image: URL
}
export interface IPEVKPost extends IPost {
    like_count?: number
    comment_count?: number
    reposts_count?: number
    images?: URL[]
    link: URL
    url?: VK_URL
}

export interface IApolloData {
    filter: any
    order_by_meta: any
    fill: Color[]
} 
export interface IDataTypeField {

}

export interface IAppoloField {
    type: SCALAR_TYPES | string
    title: string
    thread: boolean | number
    editable: boolean | number
    hidden?: boolean
}
export interface IAppoloFields {
    [fieldName: string]: IAppoloField
}
export interface IDataType {
    admin_data: IApolloData
    apollo_fields: IAppoloFields
    visibled_value: string
    type: string
    kind?: string
    admin_view? : string
    feed_view? : string 
    name: string
    plural: string
    module: string
    childRoutes? : string[]
    hidden: boolean
    icon: URL
}

export const DefaultLayoutTemplateAside = {
    bg: "transparent",
    color: "#444",
    active: "#660000" 
}
export interface ILayoutTemplateAside {
    bg:  Color
    color:  Color
    active:  Color
}
export interface IFlap {
    position: Position
    size: string /* "50%" */
    thumbnail?: URL
} 
export interface ILayoutTemplateExtendParams { 
    bgThumbnail?: URL
    bgBlur?: 1|2|3|4|5|6|7|8|9|10
    backgroundColor?: Color
    color?: Color
    bgOpacity?: Number /* 0 - 1 */
    flap?: IFlap
} 
export type ILayoutTemplate = {
    menu_left: boolean
    main_left: ILayoutTemplateAside | false
    left: ILayoutTemplateAside| false
    is_left: boolean
    is_right: boolean
    header: ILayoutTemplateAside | false
    footer: ILayoutTemplateAside | false
    icon: Icon
    icon_width: number
    icon_height: number
    icon_background: Color
    avatar: Icon
    login: string | "/login"
    style: URL | string 
    default_img: URL
    thumbnail: Icon
    header_menu: "iconic" | ""
    header_menu_height: number
    left_menu: "pictogramm" | "hierarhical"
    extend_params: ILayoutTemplateExtendParams
}

export const templateTypes = (): any => {
    return {
        menu_left: {
            id: "menu_left",
            title: "Left Menu",
            commentary: "",
            type: SCALAR_TYPES.BOOLEAN,
            visualization: "layout-template-aside-scalars",
            notClear: true,
            tab: "Layout"
        },
        main_left: {
            id: "main_left",
            title: "Top Menu",
            commentary: "",
            type: SCALAR_TYPES.BOOLEAN,
            visualization: "layout-template-aside-scalars", 
            notClear: true,
            tab: "Layout"
        },
        left: {
            id: "left",
            title: "Left Menu Aside",
            commentary: "",
            type: SCALAR_TYPES.BOOLEAN,
            visualization: "layout-template-aside-scalars",
            notClear: true,
            tab: "Layout"
        }, 
        right: {
            id: "right",
            title: "Right Menu Aside",
            commentary: "",
            type: SCALAR_TYPES.BOOLEAN,
            visualization: "layout-template-aside-scalars",
            notClear: true,
            tab: "Layout"
        }, 
        header: {
            id: "header",
            title: "Header",
            commentary: "",
            type: SCALAR_TYPES.BOOLEAN,
            visualization: "layout-template-aside-scalars",
            notClear: true,
            tab: "Layout"
        },  
        footer: {
            id: "footer",
            title: "footer",
            commentary: "",
            type: SCALAR_TYPES.BOOLEAN,
            visualization: "layout-template-aside-scalars",
            notClear: true,
            tab: "Layout"
        }, 
        icon: {
            id: "icon",
            title: "Icon",
            commentary: "",
            type: SCALAR_TYPES.MEDIA,
            tab: "Head"
        },  
        icon_width: {
            id: "icon_width",
            title: "Icon width",
            commentary: "",
            min: 20,
            max:400,
            type: SCALAR_TYPES.SLIDER,
            stepSize:1,
            labelStepSize:25,
            tab: "Head"
        },   
        icon_height: {
            id: "icon_height",
            title: "Icon height",
            commentary: "",
            min: 20,
            max:100,
            type: SCALAR_TYPES.SLIDER,
            stepSize:1,
            labelStepSize:10,
            tab: "Head"
        },   
        icon_background: {
            id: "icon_background",
            title: "Icon's background",
            commentary: "",
            type: SCALAR_TYPES.COLOR,
            tab: "Head"
        },  
        avatar: {
            id: "avatar",
            title: "Default avatar",
            commentary: "",
            type: SCALAR_TYPES.MEDIA,
            tab: "Over"
        },   
        login: {
            id: "login",
            title: "Login path",
            commentary: "",
            type: SCALAR_TYPES.STRING,
            default: "/login",
            tab: "Over"
        },    
        style: {
            id: "style",
            title: "Style URL",
            commentary: "",
            type: SCALAR_TYPES.RADIO,
            values: [
                {
                    _id:"/assets/css/style.css",
                    title: "Default"
                },
                {
                    _id:"/assets/css/style_light.css",
                    title: "Light"
                },
                {
                    _id:"/assets/css/style_minimal.css",
                    title: "Minimal"
                },
            ],
            default: "/assets/css/style.css",
            tab: "Over"
        },   

        // default_img: {
        //     id: "default_img",
        //     title: "Default Image background",
        //     commentary: "",
        //     type: SCALAR_TYPES.MEDIA, 
        //     height: 250,
        //     tab: "Over"
        // },    
        // thumbnail: {
        //     id: "thumbnail",
        //     title: "Thumbnail",
        //     commentary: "",
        //     height: 250,
        //     type: SCALAR_TYPES.MEDIA, 
        //     tab: "Over"
        // },    

        header_menu : {
            id: "header_menu",
            title: "header menu type",
            commentary: "",
            type: SCALAR_TYPES.IMAGE_RADIO,
            values: [
                {
                    _id:"iconic",
                    title: "Iconic",
                    img: "/assets/img/admin/header-menu-title1.svg",
                    height: 100
                }, 
                {
                    _id:"linic",
                    title: "Linear",
                    img: "/assets/img/admin/header-menu-title2.svg",
                    height: 100
                },
            ],
            default: "iconic",
            tab: "Head"
        },     
        header_menu_height: {
            id: "header_menu_height",
            title: "header menu height",
            commentary: "",
            type: SCALAR_TYPES.NUMBER,
            tab: "Head" 
        },    
        left_menu: {
            id: "left_menu",
            title: "Left menu type",
            commentary: "",
            type: SCALAR_TYPES.IMAGE_RADIO,
            values: [
                {
                    _id:"pictogramm",
                    title: "pictogramm",
                    img: "/assets/img/admin/header-menu-title1.svg",
                    height: 100
                }, 
                {
                    _id:"hierarhical",
                    title: "hierarhical",
                    img: "/assets/img/admin/header-menu-title2.svg",
                    height: 100
                },
            ],
            default: "iconic",
            tab: "Over"
        },
        extend_params: {
            title: "Extended parameters",
            description: "Default design parameters of every View (background, colors)",
            type: SCALAR_TYPES.BOOLEAN,
            visualization: "extend-params-scalars",
            tab: "Default Views design"

        }
    }
}
export interface IWidget {
    title: string
    description: string
    module: string
    is_cabinet?: boolean
    preority: number
    area: IWidgetNest[]
    fields: IWidgetAreaField[]
}
export interface IWidgetNest {
    component : string
    [key: string] : any
}
export interface IWidgetAreaField {
    title: string
    type: SCALAR_TYPES
    default?: any
}
export interface IWidgetArea {
    title: string
    description: string
    preority: number
    area: IWidgetNest[]
    fields? : IWidgetAreaField[]
    module: string
}

export interface IModule {
    title: string
    description: string
    commentary: string
    id: string
    required : string[]
    icon: URL
    preority: number
    version: string
    author: string
    color: Color
    documentation: URL
    git: URL
    views: any      // IView[]
    widgets: any    // IWidget[]
    extentions: any
    ["widget-area"]: any
    assets: any
    schema: any
    routing: any
}

export enum DIALOG_SIZE {
    MINIMAL="minimal",
    LITTLE="little",
    NORMAL="normal",
    LARGE_ELEGNSE="large-eleganse",
    LARGE="large",
    FULL="full"
}

export enum FRAME_TRANSITION_TYPE {
    MOVE_DOWN="MOVE_DOWN",
    MOVE_UP="MOVE_UP",
    FADE="FADE"
}