import { Button, ButtonGroup } from "@blueprintjs/core" 
import { __ } from "@/libs/utilities"

interface IProps {
    selected: string[]
    setSelected: (selected: string[] ) => void
    fields: string[] 
}
const SpecialFieldsEngine = ({ 
    selected, 
    setSelected , 
    fields  
}: IProps) : JSX.Element => {  
    const checkField = (field: string) => {
        let sel: string[] = [...selected]
        if( sel.includes( field ) ) {
            sel = sel.filter( s => s !== field )
        }
        else {
            sel = [ ...sel, field ]
        }
        console.log( field, sel.includes( field ), sel )
        setSelected(sel)
    }
    return <div>
        <ButtonGroup>
            {
                fields.map((field: string) => {
                    return <Button 
                        key={field}
                        minimal={ !selected.includes( field ) }
                        onClick={() => checkField( field )}
                    >
                        { __( field ) }
                    </Button>
                })
            }
        </ButtonGroup>
    </div> 
} 

export default SpecialFieldsEngine 