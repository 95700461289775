import { isRole } from "@/settings/zustand/user"
import { __ } from "@/libs/utilities"
import { Button, ButtonGroup } from "@blueprintjs/core"
import { IExhortation, IProject } from "../../data/interfaces"
import { IFestProjectsProps, useFestProjects } from "../../data/store"
import { ScalableDialog } from "@/libs/useful"
import { DIALOG_SIZE } from "@/libs/interfaces/layouts"
import { useState } from "react"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { actions } from "../../data/actions"
import { DELETE_EXHORTATION_ACTION, SEND_EXHORTATION_ACTION } from "../../data/actionTypes"
import { useParams } from "react-router"
import { userInfo } from "os"
import { IState, IUser, useMainStore } from "@/settings/zustand"

/* Protopia Ecosystem component */
export interface IProjectExhortionBanFormProps {
    item: IExhortation
}
const ProjectExhortionBanForm = (props: IProjectExhortionBanFormProps): JSX.Element => {
    const { landId } = useParams()
    const user: IUser = useMainStore((state: IState) => state.user)
    const [ text, setText ] = useState<string>( props.item.descr ) 
    
    const onEdit = () => {
        //console.log( {landId, ...props.item, descr: text} )
        actions( SEND_EXHORTATION_ACTION, { landId, ...props.item, descr: text } )
    }
    const onDelete = () => {
        actions( DELETE_EXHORTATION_ACTION, { landId, id: props.item.id } )
    }
    const onBanUser = () => {

    }
    return isRole( [ "administrator", "moderator" ] ) ||
        props.item.author.id === user.id
        ?
        <div className='pe-project-exhortion-ban-form-container'>
            <ButtonGroup className="d-flex flex-wrap" >
                {
                    isRole( [ "administrator", "moderator" ] ) &&
                    <ConfirmButton
                        dialogClasssName={DIALOG_SIZE.MINIMAL}
                        buttonLabel={__("Ban exhortation author")} 
                        buttonMinimal
                        buttonIcon="blocked-person"
                        onConfirm={onBanUser}
                    >
                        <div className="p-4">
                            Function not work yet
                        </div>
                    </ConfirmButton>
                }
                <ConfirmButton
                    dialogTitle={__("Edit exhortation")}
                    dialogClasssName={DIALOG_SIZE.MINIMAL}
                    buttonLabel={__("Edit exhortation")} 
                    buttonMinimal
                    buttonIcon="annotation"
                    onConfirm={onEdit}
                >
                    <div className="p-4">
                        <textarea
                            value={text}
                            onChange={(evt) => setText(evt.target.value)}
                            style={{ width: "100%", height: 250 }}
                            className="input dark"
                        >

                        </textarea>
                    </div>
                </ConfirmButton>
                <ConfirmButton 
                    dialogClasssName={DIALOG_SIZE.MINIMAL}
                    buttonLabel={__("Remove exhortation")} 
                    buttonMinimal
                    buttonIcon="delete"
                    onConfirm={onDelete}
                >
                    <div className="p-4">
                       {__("Real remove exhortation?")}
                    </div>
                </ConfirmButton>
            </ButtonGroup>
            
        </div>
        :
        <></>
        // <div className='pe-project-exhortion-ban-form-container'>
        //     <ButtonGroup >
        //         <Button icon="annotation" minimal >
        //             {__("Complain")}
        //         </Button>
        //     </ButtonGroup>
        // </div>
}
export default ProjectExhortionBanForm