import { __ } from '@/libs/utilities'
import { isRole } from '@/settings/zustand/user'
import { Outlet } from 'react-router'
import { NavLink } from 'react-router-dom'
import { getModeratorRoles } from '../../data/utils/getModeratorRoles'

const StatisticForm = () : JSX.Element => { 

  return isRole(getModeratorRoles())
    ?
    <div className=' my-5 '> 
      <div className='row ' id="fest-head-switcher">
          <div className='col-12 milestones-cont d-flex'>
              <div className='pt-1 pr-5 display-6 '>{__("Statistics")}</div>
              <NavLink
                to="all-members"
                className={"milestone-button"}
              >
                { __("Members") }
              </NavLink>
              <NavLink
                to="users"
                className={"milestone-button"}
              >
                { __("Users and rates") }
              </NavLink>
              {/* <NavLink
                to="synthesizer"
                className={"milestone-button"}
              >
                { __("Specials") }
              </NavLink> */}
              <NavLink
                to="specials"
                className={"milestone-button"}
              >
                { __("Synthesizer") }
              </NavLink> 
          </div>                 
      </div>                  
      <div  className='container-fluid px-4' > 
        <div  className='row' > 
          <Outlet />
        </div>
      </div>
  </div>
  :
  <div className=" alert alert-danger p-5 lead text-center">
      {__("You haven't rights")}
  </div>
}

export default StatisticForm