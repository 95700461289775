import { ID } from "@/libs/interfaces/layouts"
import { LoaderBallsLine, Loading } from "@/libs/useful"
import { __ } from "@/libs/utilities"
import Feed from "@/modules/pe-basic-module/views/core/Feed"
import { IState, useMainStore } from "@/settings/zustand"
import { isRole } from "@/settings/zustand/user"
import { useState } from "react"
import Message from "./Message"
import { getModeratorRoles } from "../../data/utils/getModeratorRoles"

/* Protopia Ecosystem component */
export interface IMessagesFormProps {
   
}
const MessagesForm = (props: IMessagesFormProps): JSX.Element => { 
    const festId: ID = useMainStore((state: IState) => state.landId) || "-1" 
    const [isLoading, setIsLoading] = useState<boolean>(false)
     
    const paging = () => { 
        return ` order_by_meta: "id" `
    }
    console.log( "MessagesForm", festId )
    if(isLoading) {
        return <div className="layout-state">
            <Loading />
        </div>
    }
    return isRole( getModeratorRoles() ) 
        ?
        <div className=' container-fluid '>
            <div className="container display-6 my-3 d-flex">
                {__("User messages")}
                { isLoading && <LoaderBallsLine /> }
            </div>
            <Feed 
                data_type={ "PEMessage" }  
                offset={0}
                land_id={festId}
                component={Message}
                class_name="container"
                paging={ paging() }
                onLoading={() => setIsLoading(false)}
                params= {{
                    col: 1
                } }
            />
        </div>
        :
        <></>
}
export default MessagesForm