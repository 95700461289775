import React, { Component } from "react" 
import {isImageExt} from "../utilities"; 

class LayoutIcon extends Component<ILayoutIcon> {
	state = { ...this.props };
	isImage() 
	{
		const { src } = this.props
		return 	src?.indexOf( "http" ) !== -1 || 
				isImageExt( src?.split(".")[1] || "" )
	}
	componentDidMount() { 
		// console.log(this.props.src, this.isImage() )
		this.isImage() 
			? 
			this.getImage(this.props.src) 
			: 
			this.getStyle(this.props.src)
	} 
	componentDidUpdate(prevProps: Readonly<ILayoutIcon>, prevState: Readonly<{}>, snapshot?: any): void {
		if(prevProps.src !== this.props.src) {
			this.isImage() 
				? 
				this.getImage(this.props.src) 
				: 
				this.getStyle(this.props.src)
		}
	}
	onClick = () => {
		if(this.props.onClick) {
			this.props.onClick( null )
		}
	}
	render() {
		return (
			<div
				className={this.props.className}
				style={{...this.props.style, width: this.props.width, height: this.props.height}}
				dangerouslySetInnerHTML={{ __html: this.state.icon as string | TrustedHTML }}
				onClick={this.onClick}
			/>
		)
	}
   
	getImage(src: string) { 
		const { className } = this.props
		// console.log( src )
		src = src?.indexOf("http") === -1 ? window.location.origin  + src : src
		if(!src) return ""
		fetch( src, { headers: { "Access-Control-Allow-Origin": "*" } } )
			.then(
				(response: any) => {
					// console.log( response )
					this.setState({ icon: `<img src=${response.url} alt="" class="${className}" style="min-width: ${ this.props.style?.width }px; height: ${ this.props.style?.height }px; margin: ${this.props.style?.margin}" />` })
				},
				(error: Error) => { 
					//console.log(this.props)
					// console.error(error, this.props.src)
					this.setState({ 
						icon: `<div style="background-image: url(${ this.props.src }); width: ${ this.props.style?.width }px; min-height: ${ this.props.style?.height }px" class="thumbnail ${ className ? className : "" }"> </div>`
					})
				})
		return ""
	}

	getStyle( src: string ) {
		this.setState({ icon: `<span class='${src}'></span>` })
	}
}
export default LayoutIcon

interface ILayoutIcon {
	src: string
	className?: string
	icon?: string
	style?: any
	isSVG?: boolean
	onClick? : (any: any) => void
	width?: number | string
	height?: number | string
}
