import React, { useEffect, useState } from 'react'
import { getQueryData, schema } from 'src/libs/layouts'
import DataTableFiltersPanel from './DataTableFiltersPanel' 
import Feed from '../../../pe-basic-module/views/core/Feed' 
import DataTableLayout from './DataTableLayout'
import DataTypeRow from './DataTypeRow'
import { IAdminStore, IBulk, useAdminStore } from '../../data/store'
import { useParams } from 'react-router'
import { ID } from '@/libs/interfaces/layouts'

export const DataTable = ( props:any ) : JSX.Element => {
    const params = useParams() 
    const landId : ID = params.landId || ""
    const [count, setCount] = useState<number>(props.count || 10 )
    const bulks: IBulk[]  = useAdminStore((state:IAdminStore) => state.bulks) 
    const items: any[]  = useAdminStore((state:IAdminStore) => state.items) 
    const setBulks: ((bulks: IBulk[]) => void)  = useAdminStore((state:IAdminStore) => state.setBulks) 
    const setItems: ((items: any[]) => void)  = useAdminStore( (state:IAdminStore) => state.setItems ) 
    const data_type: string = useAdminStore((state:IAdminStore) => state.data_type) 
    const setData_type: ((data_type: string) => void)  = useAdminStore( (state:IAdminStore) => state.setData_type ) 
    const offset: number = useAdminStore((state:IAdminStore) => state.offset) 
    const setOffset: ((offset: number) => void)  = useAdminStore( (state:IAdminStore) => state.setOffset ) 
    const paging: string = useAdminStore((state:IAdminStore) => state.paging) 
    const setPaging: ((paging: string) => void)  = useAdminStore( (state:IAdminStore) => state.setPaging ) 
    useEffect(() => {
        setBulks([])
        setItems([])
        setOffset(0)
        setPaging("")
        setData_type(props.data_type)
        return () => {
            setBulks( [] )
            setItems( [] )
            setData_type( "" )
            setOffset(0)
            setPaging("")
        }
    }, [])
    useEffect(() => {
        setBulks([])
        setItems([])
        setOffset(0)
        setPaging("")
        setData_type(props.data_type)
    }, [ props.data_type ])

    const onLoading = ( items: any[],  _offset: number, count: number, paging: string ) => {
        setOffset( _offset )
        setPaging( paging )
        setItems(items || [])
        setBulks( items.map( (item:any) => {
            return {
                id: item.id,
                data_type,
                checked: false
            }
        }))
        //setTimeout( () => console.log( "zustand store:", offset, "Feed:", _offset ), 600)
    }
    const onOffset = ( _offset: number ) => { 
        // setOffset( _offset )
        useAdminStore.setState({offset:_offset})
        //setTimeout( () => console.log( "onOffset...zustand store:", offset, "Feed:", _offset ), 600)
    }
    const onChangeCount = ( count: number ) => {
        setCount(count)
    }
    const clearFilter = () => {

    } 
    const onMainBulk = (bool:boolean) => {
        console.log( bool)
    }
    console.log( props )
    return <> 
        <DataTableFiltersPanel
            data_type={ props.data_type }
            count={count}
            onChangeCount={onChangeCount}
            clearFilter={clearFilter}
        /> 
        <Feed 
            data_type={ props.data_type } 
            isLoader
            count={20} 
            offset={0} 
            order= { props.order }
            filter={ props.filter }
            class_name='row'
            containerClassName="container-fluid"
            component={ DataTypeRow }
            layoutComponent={ DataTableLayout }
            onLoading={ onLoading }
            setOffset={onOffset}
            items={ items }
            land_id={ landId }
            paging={ 
                `${schema()[props.data_type].apollo_fields.post_status ? 'post_status: "all"' : ``} order_by_meta: "id" ` 
            }
            params={{
                fields: getQueryData( props.data_type ),
                route: props.route,
                onMainBulk
            }}
        />  
        
    </>
}
