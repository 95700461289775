import { login, template } from '@/libs/layouts'
import { __, initArea } from '@/libs/utilities'
import { Link, useLocation } from 'react-router-dom' 
import { ILayoutUserProps } from './LayoutUser'

const LayoutUnloginned = (props: ILayoutUserProps) => { 
  const location = useLocation()
  return <>
  {
    initArea(
      "unlogin_panel",
      { ...props },
      <Link 
        state={{ pathname: location.pathname }}
        className={`icon-unlogined mx-3 ${template().header_menu}`} 
        to={login()} 
      > 
          <i className="fas fa-sign-in-alt mr-2"></i>
          {__("Enter")} 
      </Link>,
    )
  }
  </>
} 

export default LayoutUnloginned