import { ID, IPEMessage } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { data_type_single_route } from "@/libs/utilities/links"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { IState, useMainStore } from "@/settings/zustand"
import { Icon, Tooltip } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import Moment from "react-moment"
import { GET_FEED_ACTION } from "../../data/actionTypes"

/* Protopia Ecosystem component */
export interface IMessageProps {
   item: IPEMessage
}
const Message = (props: IMessageProps): JSX.Element => {
    const landId: ID = useMainStore((state: IState) => state.landId) || "-1" 
    const [is_viewed, setIs_viewed] = useState<boolean>(props.item.is_viewed)
    useEffect(() => {
        setIs_viewed(props.item.is_viewed)
    }, [props.item.is_viewed])
    const link = () => {
        return `${ data_type_single_route( props.item.resource_type || "Post" )?.realRoute }/${props.item.resource_id}`
    }
    const onIsViewed = () => {
        actions(
            UPDATE_SINGLE_TYPE, 
            {
                data_type: "PEMessage", 
                id:props.item.id, 
                item: { is_viewed: !is_viewed}, 
                landId
            }
        )
        .then((resp) => {
            setIs_viewed(!!resp.is_viewed)
            actions(GET_FEED_ACTION, {
                data_type: "PEMessage",
                paging: ` metas: [{ key: "is_viewed", value_bool: true }] `,
                land_id: landId,
                seed: " for admin label"
            })
        })
    }
    const _date = parseInt(props.item.post_date || "0") * 1000
    let today = new Date()
    let yesterday = new Date(today)
    yesterday.setDate(today.getDate() - .5)

    return <div className="row border-bottom border-secondary py-2 w-100">
        <div className="col-6 h-100 d-flex align-items-center">
            <Tooltip
                content={<div className="px-3 py-1">{__(!is_viewed ? "set viewed" : "set unviewed")}</div>}
            >
                <Icon 
                    icon={!is_viewed ? "star-empty" : "star"} 
                // color={ "green" } 
                    className="pr-3 opacity_75 pointer"
                    onClick={onIsViewed}
                />
            </Tooltip>
            <div className={`${is_viewed ? "title": "opacity_75"} text-overflow-ellipse `}>
                {props.item.title}
            </div>
        </div>
        <div className="col-3 h-100 d-flex align-items-center">
            <a href={link()} target="_blank" rel="noreferrer">
                {props.item.resource_title}
            </a>
        </div>
        <div className="col-2 h-100 d-flex align-items-center">
            <div className="title small" >
                {props.item.post_author?.display_name}
            </div>
        </div>
        <div className="col-1 h-100 d-flex align-items-center">
            <Moment  
                locale="ru" 
                format={ _date > yesterday.getTime() ? "HH:mm" : "D.MM.YY" } 
                className={`small title`}  
            >
                { _date } 
            </Moment> 
        </div>
    </div>
}
export default Message