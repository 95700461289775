import { __ } from "@/libs/utilities"
import { IPEFestSchool } from "../../data/interfaces"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import FestSchoolCard from "./FestSchoolCard"

/* Protopia Ecosystem component */
export interface IAboutSchoolListProps {
   
}
const AboutSchoolList = (props: IAboutSchoolListProps): JSX.Element | false  => {
    const schools: IPEFestSchool[] = useDataStore((state: any) => state.PEFestSchool) || []
    // console.log(schools)
    
    return !!schools?.length && <>
        <div className="row border-top opacity_01"/>    
        <div className="container" id="schools">
            <div className="row justify-content-center pt-4 pb-4 px-md-5 px-2">
                <div className="col-md-12 d-flex justify-content-center">
                    <div className="role_descr">
                        {__("Education organizations on Festival")}
                    </div>
                </div>
            </div>
            <div className="row justify-content-center px-2 pb-4">
            {
                schools?.map( 
                    (school: IPEFestSchool) => <FestSchoolCard item={school} key={school.id} /> 
                )
            }
            </div>
        </div>
        <div className="row border-top opacity_01"/>
    </> 
}
export default AboutSchoolList