import { useState } from 'react'
import { WINDOW_WIDTH } from '../interfaces/layouts'
import LayoutHeaderMobile from './header/LayoutHeaderMobile'
import LayoutHeadeScreen from './header/login'

const LayoutHeader = ( props: any ) : JSX.Element => { 
	
	const [isMobyle, ] = useState( window.innerWidth < WINDOW_WIDTH.TABLET )
	return isMobyle
		?
		<LayoutHeaderMobile />
		:
		<LayoutHeadeScreen /> 
} 

export default LayoutHeader