import React from 'react' 

export interface IFeedLayoutProps {
    layoutComponent?: any
    children: JSX.Element | string
    isLoading? : boolean
}
const FeedLayout = ( props: IFeedLayoutProps ): JSX.Element => {
    const Layout  = props.layoutComponent
        ?
        props.layoutComponent
        :
        DefaultFeedLayout
    return <Layout { ...props }> 
        { props.children }
    </Layout> 
}

export default FeedLayout

interface IDefaultFeedLayoutProps {
    containerClassName?: string
    class_name?: string
    style?: React.CSSProperties
    children: JSX.Element | string
}
const DefaultFeedLayout = (props:IDefaultFeedLayoutProps): JSX.Element => {
    return <div className={ ` ${props.containerClassName || "d-flex" } ` }> 
        <div className={ `row ${props.class_name}`  } style={ props.style }>
            { props.children }
        </div> 
        
    </div> 
}