import { compareArrays } from "@/libs/utilities"
import { CARD_SORT_TYPE, FEST_FILTER_LABEL, IFestFilters, IFestival } from "../interfaces"
import { useFestDataStore, useFestStore } from "../store"
import { isRole } from "@/settings/zustand/user"

export const getGQLPagingFilters = (
    filters: IFestFilters,
    cardSort: CARD_SORT_TYPE,
    search: string,
    over: string = ""
): string => {

    const festival: IFestival = useFestDataStore.getState().festival
    // example: taxonomies:[{ tax_name: "fmru_track", term_ids: [47] }] 
    const filterdata: any = {
        // destrict : {
        //     field: "fmru_destrict"
        // },
        schools : {
            field: "fmru_school"
        },
        tracks: {
            field: "fmru_track"
        },
        honeycombs: {
            field: "fmru_honeycombs"
        },
        ganres: {
            field: "fmru_group_player"
        }
    }
    let paging: string[] = []
    Object.keys(filterdata).forEach(( key: string ) => {
        const k: FEST_FILTER_LABEL = key as FEST_FILTER_LABEL
        const elem = filterdata[ key ]
        if (!compareArrays([-1], filters[k]) && !compareArrays(["mine"], filters[k])) {
            paging.push(`{tax_name: "${ elem.field}", term_ids: [ ${filters[k].join(",")} ] }`)
        }
    })

    //metas
    //isHideNotVerifiedProjects
    let metas : string = ''
    if(!!festival.isHideNotVerifiedProjects && !isRole(["administrator", "Track_moderator", "School_guardian"])) {
         metas = `, metas: [ {key: "is_verified", value_bool: true} ] `
    }
    else {
        if( useFestStore.getState().isVerified && useFestStore.getState().isNotVerified ){

        }
        else if( useFestStore.getState().isVerified ) {
            metas = `, metas: [ {key: "is_verified", value_bool: true} ] `
        }
        else if( useFestStore.getState().isNotVerified ) {
            metas = `, relation: "OR",
            metas: [ 
              { key: "is_verified" value_bool: false }
              { key: "is_verified" compare: "NOT EXISTS" }
            ]  `
        }
    }
    

    // sort
    let sort: string = ``
    switch (cardSort) {
        case CARD_SORT_TYPE.ACS:
            sort = ` order_by_meta: "title" , order: "ASC"`
            break
        case CARD_SORT_TYPE.RACS:
            sort = ` order_by_meta: "actualRating" , order: "ASC"`
            break
        case CARD_SORT_TYPE.NACS:
            sort = ` order_by_meta: "order" , order: "ASC"`
            break
        case CARD_SORT_TYPE.DECS:
            sort = ` order_by_meta: "title" , order: "DESC"`
            break
        case CARD_SORT_TYPE.RDECS:
            sort = ` order_by_meta: "actualRating" , order: "DESC"`
            break
        case CARD_SORT_TYPE.NDECS:
        default:
            sort = ` order_by_meta: "order" , order: "DESC"`
    }

    // search
    const searching = sort ? `search:"${search}"` : ``

    let ret: string = !!paging.length
        ?
        `taxonomies:[ ${paging.join(" ")}], ${[sort, searching].join(", ")}  ${over}`
        :
        ` ${[sort, searching].join(", ")}   ${over}`
    ret = ret + metas
    // console.log( ret )
    return ret
} 