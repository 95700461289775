export const SNAPSHOT_LAND = (data: any): string => {
    return `
    query get_full_festival_body ($land_id: ID) {
        get_full_festival_body(land_id:$land_id) 
    }
    ` 
}

/*

            let projects="", 
                raitingData ="",  
                ratingSystem ="",  
                users ="" 
            
            if(data.input.projects !== "IGNORE") {
                projects = `
                    projects {
                        ${getQueryArgs("PEFestProject") }
                    }
                    tracks {
                        ${getQueryArgs("PEFestTrack")}
                    }    
                    honeycombs {
                        ${getQueryArgs("PEFestHoneycombs")}
                    }                         
                    destricts {
                        ${getQueryArgs("PEFestDestrict")}
                    }
                    schools {
                        ${getQueryArgs("PEFestSchool") }
                    }
                    ganres {
                        ${getQueryArgs("PEFestGanre") }
                    }
                    diaries {
                        ${getQueryArgs("PEFestDiary") }
                    } 
                `
            }
            if(data.input.ratingSystem !== "IGNORE") {
                ratingSystem = `
                    milestones {
                        ${getQueryArgs("PEFestMilestone") }
                    }
                    categories {
                        ${getQueryArgs("PEFestCategory")}
                    }    
                    criteries {
                        ${getQueryArgs("PEFestCritery")}
                    }
                `
            }
            if(data.input.raitingData !== "IGNORE") {
                raitingData = `
                    ratings {
                        ${getQueryArgs("PEFestRating") }
                    }
                `
            }
            if(data.input.users !== "IGNORE") {
                users = `
                    users {
                        ${getQueryArgs("User") }
                    }
                `
            }
            _query = `query shapshotPEFestivalData {
                shapshotPEFestivalData(
                    input: {
                        projects: ${data.input.projects},                        
                        raitingData: ${data.input.raitingData},                        
                        ratingSystem: ${data.input.ratingSystem},                        
                        users: ${data.input.users},                        
                    },
                    land_id: "${data.land_id}"
                ){
                    land {
                        id
                        name
                        event_types
                        domain
                        domain_content
                        description
                        domain_description
                        domain_type
                        domain_type_id
                        status
                        defaultThumbnail
                        defaultThumbnailName
                        startDate
                        finishDate
                        icon
                        icon_id
                        create_date
                        owner {
                            id
                            display_name
                        }
                        geo
                        demiurg_id
                        images
                        isPattern
                    }
                    festival {
                        id
                        title
                        content
                        description
                        domain_type
                        domain_type_id 
                        clientUrl 
                        reglamentTitle 
                        reglament
                        refreshRate 
                        maxMemberId
                        memberCardType
                        memberPerPage
                        horoRaiting
                        enabledRules
                        isRegisterUser
                        isExpertsCriteries
                        isHideNotVerifiedProjects
                        isIndependentGradeCritery
                        isProjectsVerify
                        isOwnerRate
                        isGanres
                        enabledReglament
                        isRegisterProject
                        isCreateAuthorWithProject
                        isShowMap
                        isShowReglament
                        isShowLaureates
                        isShowSchools
                        isShowTracks
                        isDiary
                        isChangeCardType
                        maxRating 
                        countOfResults
                        displayProjectResult
                        startDate
                        finishDate
                        maxGanres
                        memberCardHeight
                        memberCount
                        ratingType
                        roles
                        availableRoles
                        status
                        clientUrl
                        logotype
                        icon
                        defaultProjectThumbnail
                        defaultThumbnail
                        link1
                        link2
                        email
                        email2
                        vk
                        vk2
                        telegramm
                        telegramm2
                        phone
                        phone2    
                        extendedFields {
                        id
                        count
                        name
                        description
                        values
                        type
                        yandex_form_field
                        require
                        hide_in_page
                        hide_in_card
                        hide_in_projects
                        }
                        vk_app_id
                        newProjectMessage
                        domain_content
                    }
                    ${projects}
                    ${users}
                    ${ratingSystem}
                    ${raitingData}
                }
            }` 
            console.log( _query )
            */