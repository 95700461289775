

import MediaListForm from "src/modules/pe-admin-module/views/media-list/MediaListForm";
import CommunicationsForm from "./CommunicationsForm";
import InheritanceForm from "./InheritanceForm"; 
import RoleCarriersForm from "./RoleCarriersForm";
import { useFestDataStore } from "src/modules/pe-fest-module/data/store";
import { useToposStore } from "../../data/store";
import EventTypeForm from "src/modules/pe-fest-module/views/festAdmin/EventTypeForm";


export const getAdminRoutes: any = ( ) => {    
    const eventTypes  = useFestDataStore.getState().site.event_types
    const eventTypeAdminData: any = useToposStore.getState().eventTypeAdminData
    let addedData: any = {}
    Object.keys(eventTypeAdminData)
        .filter( key => { 
            return eventTypes?.includes(key)
        })
        .forEach( key => { 
            if(!!eventTypeAdminData[key]) {
                addedData = {...addedData, ...eventTypeAdminData[key]}
            }
        })  
    const data: any =  {
        eventTypes : {
            title: "Events setting",
            group: "Events setting",
            component: EventTypeForm,
            position: "left", 
            icon: <i className="fas fa-calendar-alt"></i>, 
        },
        abbyss : {
            title: "Abyss utilities",
            group: "Events setting",
            component: InheritanceForm,
            position: "right"
        },
        media : {
            title: "Media library",
            group: "Events setting", 
            component: MediaListForm,
            position: "right"
        },
        communications : {
            title: "Communications",
            group: "Events setting",
            component: CommunicationsForm,
            position: "left"
        },
        messages : {
            title: "User messages",
            group: "Messages",
            data_type: "PEMessage",
            position: "left", 
            icon: <i className="fas fa-envelope"></i>, 
        },
        "role-carriers" :  {
            title: "Role carriers",
            component: RoleCarriersForm,
            group: "Users management",
            position: "right",
            icon: <i className="fas fa-user-tag"></i>
        },
        /*
        'main-page' : {
            title: "Дизайн главной страницы",
            group: "Festival setting", 
            component: MainPageForm,
            position: "left",
            icon: <i className="fas fa-suitcase"></i> 
        },
        settings : {
            title: "Settings",
            group: "Festival setting", 
            component: SettingsForm,
            position: "left"
        },
        projects :  {
            title: "Projects",
            data_type: "PEFestProject",
            group: "Festival setting",
            undergroup: "Landscape management",
            position: "left",
            icon: <i className="fas fa-university"></i>
        },
        tracks :  {
            title: "Tracks",
            data_type: "PEFestTrack",
            group: "Festival setting",
            undergroup: "Landscape management",
            position: "left"
        },
        destricts :  {
            title: "Destricts",
            data_type: "PEFestDestrict",
            group: "Festival setting",
            undergroup: "Landscape management",
            position: "left"
        },
        schools :  {
            title: "Education organizations",
            data_type: "PEFestSchool",
            group: "Festival setting",
            undergroup: "Landscape management",
            position: "left"
        },
        honeycombs :  {
            title: "Honeycombs",
            data_type: "PEFestHoneycombs",
            group: "Festival setting",
            undergroup: "Landscape management",
            position: "left"
        },
        "ex-fields" : {
            title: "Project ex-fields",
            group: "Festival setting",
            undergroup: "Landscape management",
            component: ExternalProjectFieldsForm,
            position: "right"
        },
        criteries :  {
            title: "Criteries",
            data_type: "PEFestCritery",
            group: "Festival setting",
            undergroup: "Referee management",
            position: "left",
            icon: <i className="fas fa-tachometer-alt"></i>
        },
        categories :  {
            title: "Categories",
            data_type: "PEFestCategory",
            group: "Festival setting",
            undergroup: "Referee management",
            position: "left",
            icon: <i className="fas fa-tachometer-alt"></i>
        },
        milestones :  {
            title: "Milestones",
            data_type: "PEFestMilestone",
            group: "Festival setting",
            undergroup: "Referee management",
            position: "left"
        },
        ganres :  {
            title: "Ganres",
            data_type: "PEFestGanre",
            group: "Festival setting",
            undergroup: "Referee management",
            position: "left"
        },
        */
        accounts :  {
            title: "Users accounts",
            data_type: "User",
            group: "Users management",
            position: "left",
            icon: <i className="fas fa-user-friends"></i> 
        },
        // "auto-created-accounts" :  {
        //     title: "Autocreated Users",
        //     component: AutoCreatedUsersForm,
        //     group: "Users management",
        //     position: "right"
        // },
        ...addedData
    } 
    return data
} 