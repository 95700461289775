import { Outlet, Route, Routes, useParams } from 'react-router-dom'
import { useFestival } from '../../hooks/festival'
import About from './About'
import AboutContainer from './AboutContainer'
import InsertNewProject from './InsertNewProject'
import Reglament from './Reglament'
import { ID } from '@/libs/interfaces/layouts'
import Loading from '@/stories/Loading'

const AboutForm = ( { ...props }: any ) : JSX.Element => {
    const params = useParams()
    const festId: ID = params.landId || ""
    const isFestLoading = useFestival( true, festId )  
     
    if( isFestLoading ) return <div className="h-100 w-100 flex-center">
        <Loading />
    </div>  
    return  <>
        <Routes>
            <Route element={<AboutContainer />} >
                <Route index element={<About />} />
                <Route path="reglament" element={ <Reglament /> } />
                <Route path="insert-project" element={ <InsertNewProject /> } />
            </Route>
        </Routes>
        <Outlet />
    </> 
} 

export default AboutForm