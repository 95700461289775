
import { Route, Routes } from "react-router"
import Festival from "./Festival"
import FestivalList from "./FestivalList"

const FestivalViewForm = (props:any) :JSX.Element => {  
    return <Routes> 
        <Route index element={<FestivalList { ...props } />} />
        <Route path={`fest`} element={<FestivalList { ...props } />} />  
        <Route path={`fest/:landId/*`} element={<Festival />} /> 
    </Routes>
} 
export default FestivalViewForm