import { __ } from "@/libs/utilities"
import { useEffect, useState } from "react"
import { IFestival, IFormField } from "../../data/interfaces"
import { AppToaster, Loading } from "@/libs/useful"
import { useFestival } from "../../hooks/festival"
import { IFestDataStor, useFestDataStore } from "../../data/store"
import FormFields from "./FormFields"
import { Icon, Intent } from "@blueprintjs/core"
import gql from "graphql-tag"
import { IState, useAppoloStore, useMainStore } from "@/settings/zustand"
import {default as UUID} from "node-uuid"
import { ID } from "@/libs/interfaces/layouts"
import { useParams } from "react-router"


interface IProps {

}
const ExternalProjectFieldsForm = (props:IProps) :JSX.Element => {
    const params = useParams() 
    const festId: ID = params.landId || "" ; //useMainStore((state: IState) => state.landId)
    const isLoading = useFestival(true, festId) 
    const festival: IFestival = useFestDataStore((state:IFestDataStor) => state.festival)
    const[ extendedFields, setFormFields ] = useState( festival.extendedFields || [] )
    const[ def, setDef ] = useState( JSON.stringify( festival.extendedFields || []) )
    const[isUpdate, setIsUpdate] = useState<boolean>(false)

    useEffect( () => useFestDataStore.subscribe(
        (state:IFestDataStor) => {
            setFormFields( state.festival.extendedFields || [] )
            setDef( JSON.stringify( state.festival.extendedFields || []) )
        }
    ), [] )

    const updateFestival = () => {
        const mutation: string =  `
            mutation changeFestival {
                changePEFestival (
                    input: {
                        extendedFields: ${
                            getExtendedsString( extendedFields )
                        }
                    }
                    ${festId ? `land_id: "${festId}"` : ""}
                ) {
                    extendedFields {
                        id
                        type
                        name
                        count
                        hide_in_page
                        hide_in_card
                        hide_in_projects
                        highlighted
                        description
                        require
                    }
                }
            }`
        // console.log( mutation )
        const apolloClient: any = useAppoloStore.getState().client
        setIsUpdate(true)
        apolloClient.mutate({  mutation : gql`${mutation}` })
            .then(( response: any ) => {
                // console.log( { ...festival, ...response.data.changePEFestival } )
                const _festival:IFestival = { ...festival, ...response.data.changePEFestival }
                useFestDataStore.getState().setFestival( _festival )
                setDef( JSON.stringify( _festival ) )
                setIsUpdate(false)
                localStorage.removeItem( "pe-fest-data-storage" )
                AppToaster.show({
                    message: __("Successful update"),
                    intent: Intent.SUCCESS
                })
            })
    }

    if( isLoading ) return <Loading />
    
    const onFormFieldChange = (form_fields: IFormField[] ) =>
	{
        console.log( form_fields )
		onUpdate("form_fields", form_fields);
		setFormFields( form_fields )
	}
    const onUpdate = ( param: string, value: IFormField[] ) =>
    { 

    } 
    return <div className="position-relative mb-5">

        <div 
            className={ ` position-sticky mr-2 mt-2 top` }
            style={{ zIndex: 100 }}
        >
            <div 
                className={` mt-2 position-absolute right btn btn-sm btn-${( JSON.stringify(extendedFields) !== def) ? 'danger' : 'secondary opacity_5 untouchble'}`}
                onClick={updateFestival} 
            >
                <Icon 
                    icon={ isUpdate ? "repeat" : "tick" }
                    className={ isUpdate ? "mr-2 fa fa-spin" : "mr-2" }
                /> 
                {__("Update Extended Fields data")}
            </div>
        </div>

        <div className="lead mb-1">{__("Every Member's fields")}</div>
        <div className="small my-2">
            {__("These fields will be displayed in each Project's account with their own values. These values can be edited by Project owners")}
        </div>
        <FormFields
            data={ extendedFields }
            onChange={ onFormFieldChange } 
        />
    </div>
} 

export default ExternalProjectFieldsForm


const getExtendedsString = ( extendeds: IFormField[]) => {  
    const str :string[] =  extendeds.map((e:IFormField) => {
        return `
                            {
                                id : "${ e.id || UUID.v4() }",
                                name: "${e.name || ""}",
                                type: "${e.type || "string"}",
                                count: ${e.count || 0},
                                hide_in_page: ${e.hide_in_page || false},
                                hide_in_card: ${e.hide_in_card || false},
                                hide_in_projects: ${e.hide_in_projects || false}, 
                                highlighted: ${e.highlighted || false}, 
                                description: "${e.description || ""}",
                                require: ${e.require || false}
                            }`
    }) 
    return `[${ str.join(`,`) }]`
}