import React from 'react'
import { __ } from 'src/libs/utilities'
import { IPost } from 'src/libs/interfaces/layouts'
import { IFestFilters, IGanre, IHoneycombs, ITrack } from '../../data/interfaces'
import { IFestDataStor, IFestStore, useFestDataStore, useFestStore } from '../../data/store'
import { Intent, Popover, Position, Tag } from '@blueprintjs/core'

const FiltersLabel = () : JSX.Element => {
    const filters: IFestFilters = useFestStore((state:IFestStore) => state.filters)
    const tracks: ITrack[]          = useFestDataStore((state:IFestDataStor) => state.tracks)
    const honeycombs: IHoneycombs[] = useFestDataStore((state:IFestDataStor) => state.honeycombs)
    const ganres: IGanre[]          = useFestDataStore((state:IFestDataStor) => state.ganres)
    return (
        <div className="d-flex mr-4">
            <SingleFilterLabel 
                title={"Tracks"} 
                items={ tracks.filter((track: ITrack) => filters.tracks.includes(track.id))} 
            />
            <SingleFilterLabel 
                title={"Honeycombs"} 
                items={ honeycombs.filter((track: IHoneycombs) => filters.honeycombs.includes(track.id))} 
            />
            <SingleFilterLabel 
                title={"Ganres"} 
                items={ ganres.filter((track: IGanre) => filters.ganres.includes(track.id))} 
            />
        </div>
    )
} 

export default FiltersLabel

const SingleFilterLabel = (props: any) : JSX.Element | null => {
    return !!props.items.length
        ?
        <div className='d-flex'>
            <div className=' mr-2'>{ __( props.title ) }:</div>
            {
                props.items.length < 2
                ?
                props.items.map((item:IPost) => {
                    return <Tag 
                        key={ item.title }
                        className='mx-1 px-3' 
                        style={{maxWidth: 270 }} 
                        round intent={Intent.SUCCESS}
                    >
                        { item.title }
                    </Tag>
                })
                :
                <Popover
                    interactionKind="hover"
                    content={ <TagOnlyMine items={ props.items }/> }  
                    position={Position.TOP_RIGHT}
                >
                   <Tag 
                    className='mx-1 px-3 pointer'
                    round intent={Intent.SUCCESS} 
                    >
                        { props.items.length }
                    </Tag> 
                </Popover>
                
            }
       
        </div>
        :
        null
}

interface ITagOnlyMine {
    items: IPost[]
}
const TagOnlyMine = ( { items }: ITagOnlyMine ) : JSX.Element => {
    return <div className='p-3'>
        {
            items.map( (i: IPost) => <div key={i.id}> {i.title} </div> )
        }
    </div>
}