import { ICorrectRating } from '@/modules/pe-fest-module/data/interfaces'
import { isRole } from '@/settings/zustand/user'
import { ID, Role } from '@/libs/interfaces/layouts'
import { SCALAR_TYPES, VerticalType } from '@/libs/scalars/interface/IScalarForm'
import { Button, ButtonGroup, Icon, Intent } from '@blueprintjs/core'
import FieldInput from '@/libs/scalars'
import { __ } from '@/libs/utilities'
import { useEffect, useState } from 'react'
import MemberRatingDisplay from '../../projectList/MemberRatingDisplay'
import CriteryCommentaryDialog, {
    SEND_DESCRIPTION_EXTEND_LABEL,
    SEND_DESCRIPTION_LABEL
} from './CriteryCommentaryDialog'
import { useParams } from 'react-router'
import { actions } from '@/modules/pe-fest-module/data/actions'
import { CORRECT_PROJECT_RATING_ACTION } from '@/modules/pe-fest-module/data/actionTypes'
import { IState, IUser, useMainStore } from '@/settings/zustand'

interface ICorrectRateProps {
    countedRating: string
    correctMemberRate: ICorrectRating
    itemId: ID
}
const CorrectRaiting = (props: ICorrectRateProps) :JSX.Element => {
    const {landId} = useParams()
    const user : IUser = useMainStore((state: IState) => state.user)
    const myRating: number = parseFloat(props.countedRating?.substring(0, 4) || "0") 
    const rsting: number = isNaN(myRating) ? 0 : myRating
    const [rating, setRating] = useState<number>( 
        props.correctMemberRate && props.correctMemberRate.rate !== 0
            ? 
            props.correctMemberRate.rate
            : 
            0 
    )
    const [description, setDescription] = useState( 
        props.correctMemberRate 
            ? 
            props.correctMemberRate.description 
            : 
            "" 
    )
    const [isTrackEditForms, setTrackEditForms] = useState(false)
    useEffect(() =>
    {
        setRating (
            !!props.correctMemberRate 
                ? 
                props.correctMemberRate.rate 
                : 
                0
        )  
        setDescription(
            props.correctMemberRate 
                ? 
                props.correctMemberRate.description 
                : 
                ""
        )   
    }, [ props.correctMemberRate ])
    const isTrackOwner = () =>
    {
        return isRole(["Track_moderator", "School_guardian"]) 
    }
    const onChange = (value:number) =>
    { 
        setRating( value )
    } 
    const onChangeDescription = (_description: string) =>
    { 
        console.log(_description)
        setDescription( _description )
    } 
    const correctRate = ( bool: boolean, _description: string ) => {
        //TODO: actions -- correct rating 
        console.log(_description)
        actions(
            CORRECT_PROJECT_RATING_ACTION, 
            {
                landId,
                id: props.itemId,
                rate: !bool ? rating : 0,
                unixtime: Date.now(),
                user: user.id,
                display_name: user.display_name,
                description : _description,
            }
        )
        .then(( resp:any ) => {
            console.log(resp)
            setTrackEditForms(false)

        })
    } 
    const ratingLabel = rating || !isTrackEditForms ? rating :  rsting
    return isTrackOwner()
        ?
        <div className='d-flex justify-content-between'> 
            <div className={`lead py-2 d-flex w-50 `}>
                <span className={`px-2 title ${ ratingLabel != 0 ? "no-no-no" : "" } h-40px w-100 flex-centered`}> 
                    { rsting } 
                </span> 
            </div>
            <div className={ !!rating || isTrackEditForms ? "" :"hidden" }>
                <FieldInput          
                    vertical={VerticalType.VERTICAL}
                    notClear
                    editable={ isTrackEditForms }
                    type={ SCALAR_TYPES.NUMBER }
                    min={ 0 }
                    stepSize={ 0.1 } 
                    value={ ratingLabel }  
                    inputStyle={{fontSize:"1.25rem!important", textAlign:"center"}}
                    onChange={ onChange } 
                />
            </div>
            
            <Menu 
                {...props} 
                description={description}
                isUpdate={ rating !==  rsting }
                isTrackEditForms={isTrackEditForms}
                setTrackEditForms={setTrackEditForms}
                correctRate={correctRate}
                onChangeDescription={onChangeDescription}
            />
        </div>
        :
        <MemberRatingDisplay
            rating={rating}
            description={description}
            correct_member_rate={props.correctMemberRate}
            countedRating={ rsting }
            className={"lead py-0 title"}
        />
}

CorrectRaiting.propTypes = {}

export default CorrectRaiting

const Menu = (props: any): JSX.Element | null =>
{
    const [isUpdate, setUpdate] = useState( props.isUpdate )
    const [isEditOpen, setEditOpen] = useState( false )
    const [sendLabel, setSendLabel] = useState( SEND_DESCRIPTION_LABEL )
    useEffect(() => {
        setUpdate(props.isUpdate)
    }, [props.isUpdate])
    
    if(props.user?.roles.filter( (role: Role) => role === "Track_moderator" ).length === 0) 
        return null
    const doUpdate = ( description: string ) =>
    {
        setUpdate( false ) 
        props.correctRate( false, description ) 
        setEditOpen(false)
    }
    const doReset = () =>
    {
        setUpdate( false )
        props.correctRate( true, "" )
        setEditOpen(false)
    }
    const onEditOpen = () =>
    {
        setSendLabel( SEND_DESCRIPTION_LABEL )
        setEditOpen(!isEditOpen)
    }
    const onUpdateOpen = () =>
    {
        setSendLabel( SEND_DESCRIPTION_EXTEND_LABEL )
        setEditOpen(!isEditOpen)
    }
    return props.isTrackEditForms
        ?
        <div className='w-100 d-flex align-items-center justify-content-end'>
            <ButtonGroup  className='pr-3'>
                <Button 
                    small 
                    minimal 
                    //disabled={ !isUpdate } 
                    className="px-3 hint hint--top" 
                    data-hint={ __("Update") }
                    onClick={ onUpdateOpen }
                    intent={ Intent.SUCCESS } 
                >
                    <Icon icon="tick" />
                    
                </Button>
                <Button 
                    small 
                    minimal 
                    className="px-3 hint hint--top" 
                    data-hint={ __("Reset") }
                    onClick={ doReset } 
                    intent={ Intent.DANGER }
                >
                    <Icon icon="trash" />                
                </Button>
                <Button 
                    small 
                    minimal 
                    className="px-3 hint hint--top" 
                    data-hint={ __("Cancel") }
                    onClick={ () => props.setTrackEditForms(false) }
                    intent={ Intent.NONE }
                >
                    <Icon icon="cross" />                    
                </Button>
            </ButtonGroup>
            <CriteryCommentaryDialog
				isOpen={isEditOpen}
				description={props.description}
				lockWithoutComment={false}
				onOpen={ onEditOpen }
                sendLabel={ __( sendLabel ) }
                isHideChangeDescr
				onSend={ description => doUpdate( description )}
			/>
        </div>
        :
        <>
            <Button 
                minimal 
                fill
                onClick={ () => props.setTrackEditForms(!props.isTrackEditForms) }
                className="text-nowrap px-4"
            >
                {__("Adjust rating")}
            </Button> 
        </>
}