import { ID } from "@/libs/interfaces/layouts"
import { IPEFestSchool, IProject } from "../../data/interfaces"
import SingleProjectForm from "./SingleProjectForm"
import { __ } from "@/libs/utilities"

/* Protopia Ecosystem component */
export interface IMySchoolProps {
   projects: IProject[]
   schools: IPEFestSchool[]
   onChangeProject: (id: ID, field: keyof IProject, value: any) => void
}
const MySchool = (props: IMySchoolProps): JSX.Element => { 
    return <div className='pe-myschool-container'>
       {
            props.schools.map(school => <SingleSchool
                school={school}
                projects={props.projects.filter(p => p.fmru_school.map(fs => fs.id).includes(school.id) )}
                onChangeProject={props.onChangeProject}
            />)
        }
    </div>
}
export default MySchool


/* Protopia Ecosystem component */
export interface ISingleSchoolProps {
    projects: IProject[]
    school: IPEFestSchool
    onChangeProject: (id: ID, field: keyof IProject, value: any) => void
}
const SingleSchool = (props: ISingleSchoolProps): JSX.Element => {
    const prj = !!props.projects.length 
        ? 
        props.projects.map((project: IProject) =>
        { 
            return <SingleProjectForm
                key={project.id}
                item={project}
                route={""}
                onChangeProject={props.onChangeProject}
            />
        })
        :
        <div className="alert alert-secondary p-5 lead text-center w-100">
            {
                __("No elements exists")
            }
        </div>
    return <div className='pe-myschool-container mb-5'>
        <div className="display-5 mt-3 h">
            {
                props.school.title
            }
        </div>
        <div className="row">
            {prj}
        </div>
    </div>
} 