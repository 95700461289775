import { DIALOG_SIZE, IPost } from "@/libs/interfaces/layouts"
import FieldInput, { SCALAR_TYPES } from "@/libs/scalars"
import { VerticalType } from "@/libs/scalars/interface/IScalarForm"
import ConfirmButton from "@/libs/useful/ConfirmButton"
import { __ } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { UPDATE_SINGLE_TYPE } from "@/modules/pe-basic-module/data/actionTypes"
import { IProject } from "@/modules/pe-fest-module/data/interfaces"
import { isRole } from "@/settings/zustand/user"
import { ButtonGroup, Collapse } from "@blueprintjs/core"
import { useState } from "react"
import { useParams } from "react-router"

const DEFAULT_MESSAGE = "You resource banned. Contact the Festival administrators."

/* Protopia Ecosystem component */
export interface IAdminBanFormProps {
   item: IProject
}
const AdminBanForm = (props: IAdminBanFormProps): JSX.Element => {
    const {landId} = useParams()
    const [reason, setReason] = useState<string>( props.item.is_verified ? __( DEFAULT_MESSAGE ) : "" )
    const [isBan, setIsBan] = useState<boolean>(false)
    const [isHide, setIsHide] = useState<boolean>(false)
    const onHide = () => {
        actions(
			UPDATE_SINGLE_TYPE,
			{
				data_type: "PEFestProject",
				id: props.item.id,
				item: { is_verified: !props.item.is_verified, verified_reason:reason },
				landId
			}
		)
    }
    return isRole([ "administrator", "SuperAdmin", "Track_moderator", "School_guardian" ]) 
        ?
        <div className='pe-admin-ban-form-container d-flex'>
        <ButtonGroup className="d-flex flex-wrap" > 
                <ConfirmButton 
                    dialogClasssName={DIALOG_SIZE.MINIMAL}
                    buttonLabel={__("Actions")} 
                    buttonMinimal
                    buttonIcon="delete"
                    onConfirm={onHide}
                >
                    <div className="p-4">
                        <Collapse isOpen={!isBan}>
                            <div className="lead" onClick={() =>setIsBan(!isBan)}>
                                { __(props.item.is_verified ? "Do revoke" : "Do verify Project") }
                            </div>
                        </Collapse>
                        <Collapse isOpen={isBan}>
                            <FieldInput
                                type={SCALAR_TYPES.TEXT}
                                placeholder={__("Send email text")}
                                value={reason}
                                onChange={ value => setReason(value) }
                                className={"w-100 h_250"}
                                title={__("Send reason")}
                                vertical={VerticalType.VERTICAL}
                                editable
                                notClear
                                commentary={__("This text sended to owners")}
                            />
                        </Collapse>
                        <Collapse isOpen={!isHide}>
                            <div className="lead" onClick={() =>setIsBan(!isBan)}>
                                { __("Delete Project?") }
                            </div>
                        </Collapse>
                        <Collapse isOpen={isBan}>
                            <FieldInput
                                type={SCALAR_TYPES.TEXT}
                                placeholder={__("Send email text")}
                                value={reason}
                                onChange={ value => setReason(value) }
                                className={"w-100 h_250"}
                                title={__("Send reason")}
                                vertical={VerticalType.VERTICAL}
                                editable
                                notClear
                                commentary={__("This text sended to owners")}
                            />
                        </Collapse>
                    </div>
                </ConfirmButton>
            </ButtonGroup>
        </div>
        :
        <></>
}
export default AdminBanForm