import { useEffect, useState } from 'react'
//import { isStatusFinished } from './utilities/isStatusOpened'
import { ID } from '@/libs/interfaces/layouts'
import { __ } from '@/libs/utilities'
import { useParams } from 'react-router'
import { actions } from '../../data/actions'
import { GET_PROJECT_MILESTONE_ACTION } from '../../data/actionTypes'
import { IProject, IProjectMilestone } from '../../data/interfaces'
import { IFestProjectsProps, useFestProjects } from '../../data/store'
import MilestoneButton from './milestone/MilestoneButton'
import MilestoneIncludeList from './milestone/MilestoneIncludeList'
import ProjectRating from './ProjectRating'


interface IProjectMilestonesProps {
    item: IProject
}

const ProjectMilestones = (props: IProjectMilestonesProps):JSX.Element => 
{ 
    const params = useParams()  
    const festId: ID = params.landId || ""
    const milestones : IProjectMilestone[] = useFestProjects( ( state:IFestProjectsProps ) => state.currentProjectMilestones ) 
    const [currentMilestoneID, setCurrentMilestoneID] = useState<ID>( -1 )
    const [update, setUpdate] = useState( true )
    
    useEffect(() => {
        actions(GET_PROJECT_MILESTONE_ACTION, {id: props.item.id, land_id: festId})
            .then( response => {
                if( response ) {
                    currentMilestoneID === -1 && setCurrentMilestoneID( response[0]?.id || -1 )
                } 
            })
    }, [ update ]) 
    
    if(!milestones) return <></>

    const milestoneList: JSX.Element[] = milestones?.map( (milestone: IProjectMilestone) =>
    {  
        return <MilestoneButton
            key={ milestone.milestone.id } 
            item={ milestone }
            currentMilestoneID = {currentMilestoneID}
            setCurrentMilestoneID={ ( id : ID ) => {
                setCurrentMilestoneID(id)
            }}
        />
    })

    const milestone: IProjectMilestone = milestones?.filter( (milestone: IProjectMilestone) => milestone.id === currentMilestoneID )[ 0 ]
    return <div className="pb-4 forms-short">
        <div className='row milestones-cont align-items-center '>
            <div className=' pr-5 '>{__("Milestones")}: </div>
            { milestoneList }
        </div>
        <MilestoneIncludeList
            memberId={props.item.id}
            member={props.item}
            milestone={ milestone }
            isEditForms 
            onUpdate={ () => setUpdate( !update ) }
        />
        
        {
            // если нужны вложения, а их нет - то скрыть
            (
                !!props.item.is_verified &&
                ( 
                    !milestone?.milestone.is_attachments_required || 
                    !!milestone.attachments.length 
                )
            )
                &&
                <ProjectRating 
                    {...props} 
                    milestone={milestone} 
                />
        }
    </div>
} 

export default ProjectMilestones