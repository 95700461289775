import { ID, IPEMessage } from "@/libs/interfaces/layouts"
import { __ } from "@/libs/utilities"
import { data_type_feed_route } from "@/libs/utilities/links"
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_FEED_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { IState, IUser, useMainStore } from "@/settings/zustand"
import { isRole } from "@/settings/zustand/user"
import { Tooltip } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getModeratorRoles } from "../data/utils/getModeratorRoles" 

/* Protopia Ecosystem component */
export interface IFestivalMessagesWidgetProps {
   
}
const FestivalMessagesWidget = (props: IFestivalMessagesWidgetProps): JSX.Element => {
    const landId: ID = useMainStore((state:IState) => state.landId)
    const user: IUser = useMainStore((state: IState) => state.user) 
    const [ count, setCount ] = useState<number>(0)
    const [ messageRoute, ] = useState<string>( data_type_feed_route( "PEMessage" ).realRoute )
    const feed : IPEMessage[] = useDataStore((state: any) => state["PEMessage for admin label"])
    useEffect(() => {
        if( !isRole( getModeratorRoles() ) ) return
        actions(GET_FEED_ACTION, {
            data_type: "PEMessage",
            paging: ` metas: [{ key: "is_viewed", value_bool: true }] `,
            land_id: landId,
            seed: " for admin label"
        })
    }, [ landId, user.roles ])
    useEffect(() => { 
        setCount( feed?.length ) 
    }, [ feed ])
   
    return isRole( getModeratorRoles() )
        ?
        <Tooltip
            content={ __( "User messages" ) }
            interactionKind="hover"
        >
            <Link 
                className={`pe-festival-messages-widget-container hover ${!count ? "" : "bg-danger"}`}
                to={ `${ messageRoute }` }
            >
                {count}
            </Link>     
        </Tooltip>        
        :
        <></>
}
export default FestivalMessagesWidget