import { IRating } from "@/modules/pe-fest-module/data/interfaces"

export interface ICriteryRatingProps {
    ratings: IRating[]
}
const CriteryRatingView = (props:ICriteryRatingProps): JSX.Element => {
    let rating: number = 0
    props.ratings.forEach(( cur:IRating ) => {
        rating += cur.rating
    })
    return <div className="footer-widget lead">
        {(( rating || 0 ) / (props.ratings.filter(r => !!r.rating ).length || 1)).toFixed(2)}
    </div>	
}

export default CriteryRatingView