import { IPost } from "@/libs/interfaces/layouts"
import { isRole } from "@/settings/zustand/user"
import MessageForm from "./MessageForm"
import AdminBanForm from "./rating/AdminBanForm"

/* Protopia Ecosystem component */
export interface IBanFormProps {
   item: IPost | any
}
const BanForm = (props: IBanFormProps): JSX.Element => {
    return isRole(["administrator", "SuperAdmin", "Track_moderator", "School_guardian"])
        ?
        <AdminBanForm item={props.item} />
        :
        <MessageForm item={props.item} />
}
export default BanForm