 
import { Intent } from '@blueprintjs/core'
import { getQueryName, querySingleName } from '../../../libs/layouts'
import { GET_FEED_ACTION, GET_FEED_COUNT_ACTION, GET_SINGLE_ACTIONS } from './actionTypes'
import asyncControllers from './controllers'
import { AppToaster } from "src/libs/useful"
import { __ } from "src/libs/utilities"



export const actions = (action: string, data: any): Promise<any> =>
{  
    let promise: Promise<any> = new Promise<any>((resolve) => resolve(null) ) 
    switch (action) {
        case GET_FEED_ACTION:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => { 
                        const name: string = getQueryName(data.data_type) 
                        const nameCount: string = querySingleName(data.data_type)
                        asyncControllers( GET_FEED_COUNT_ACTION, data ).then( r => {
                            resolve( {feed: response.data[name], count: r.data[`${nameCount}Count`] })
                        })                        
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise
        case GET_SINGLE_ACTIONS:
            promise = new Promise((resolve, reject) => {
                asyncControllers( action, data ).then(                    
                    response => { 
                        const query_name: string = querySingleName( data.data_type )  
                        resolve( response.data[ query_name ] )
                    },
                    error => { 
                        reject( error )
                    }
                )
            })
            return promise 
        default:
            return promise
    }

} 