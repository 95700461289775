import { default_login } from "@/libs/layouts"
import { IState, useMainStore } from "src/settings/zustand"
import { initArea } from '../../../utilities/getWidget'
import LayoutLoginedUser from './LayoutLoginedUser'
import LayoutUnloginned from './LayoutUnloginned' 
import LayoutUnLoginnedExternal from "./LayoutUnLoginnedExternal"

const LayoutUser = (props: ILayoutUserProps) => {
    return (
		<>
			{
				initArea(
					"before-profile",
					{ ...props },
                    
				)
			}
            <LayuotUserPanel {...props}/>
        </>
    )
}
 

export default LayoutUser

export interface ILayoutUserProps {
    isOpen: boolean
}

const LayuotUserPanel = (props: ILayoutUserProps) => { 
    const user = useMainStore(( state: IState ) => state.user)
    return user?.id 
        ? 
        <LayoutLoginedUser {...props}/> 
        : 
        default_login() === "login"
            ?
            <LayoutUnloginned {...props}/>
            :
            <LayoutUnLoginnedExternal {...props} />
}