export const MUTATION_TOKEN_ACTION = 'mutationToken'
export const MUTATION_EXTERNAL_TOKEN_ACTION = 'mutationExternalToken'
export const USER_INFO_ACTION = 'userInfo'
export const LOGOUT_ACTION = 'logout'
export const RESTORE_PASSWORD_ACTION = 'restorePassword'
export const RESTORE_FINISH_PASSWORD_ACTION = 'restoreFinishPassword'
export const RESTORE_FINISH_PASSWORD_ACCESS_ACTION = 'restoreFinishPasswordAccess'
export const RESET_PASSWORD_ACTION = 'resetePassword'
export const VERIFY_USER_ACTION = 'verifyUser' 
export const SEARCH_ACTION = 'search'
export const UPDATE_CURRENT_USER = 'updateUser'
export const GET_FEED_ACTION = 'getFeed'
export const GET_FEED_COUNT_ACTION = 'getFeedCount'
export const GET_SINGLE_ACTIONS = 'getSingle'
export const GET_THEME_ACTION = 'getTheme'
export const SWITCH_THEME_ACTION = 'switchTheme'
export const UPDATE_SINGLE_TYPE = 'updateSingle' 
export const UPDATE_BULK_ACTION = 'updateBulk' 
export const DELETE_SINGLE_TYPE = 'deleteSingle' 
export const DELETE_BULK_ACTION = 'deleteBulk' 
export const GET_POST_LIKES_ACTION ="getPostLikes"
export const GET_SEARCH_USERS_ACTION ="getSearchUsers"
export const GET_ALL_ROLES_ACTION ="getAllRoles"
export const GET_SERVICE_OPTIONS_ACTION ="getServiceOptions"
export const CHANGE_SERVAICE_OPTIONS_ACTION ="changeServiceOptions"
export const CREATE_SITE_ACTION ="createSite"
export const DELETE_SITE_ACTION ="deleteSite"
export const GET_DICTIONARY_ACTION ="getDictionary"
export const GET_RUTUBE_DATA_ACTION ="getRutubeData"
export const REGISTER_USER_ACTION ="registerUser"
export const GET_LAND_VERSION_ACTION ="getLandVersion"