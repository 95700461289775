import { Button, ButtonGroup, Dialog, Icon, IconName, Intent, MaybeElement, Popover, Position } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { DIALOG_SIZE } from "src/libs/interfaces/layouts"
import { __ } from "../utilities"

 
interface IProps {
    buttonIntent?: Intent
    buttonLabel?: string | JSX.Element
    buttonLarge?:boolean
    buttonFill?:boolean
    buttonMinimal?:boolean
    buttonClassName?: string
    buttonIcon?: MaybeElement | IconName
    buttonDisabled?:boolean
    dialogClasssName?: DIALOG_SIZE
    dialogAddedClassName?: string
    dialogTitle?: string
    children: JSX.Element
    isOpen?:boolean
    isPopover?: boolean
    popoverPosition?: Position
    popover?: string | JSX.Element
    onDesmiss?: () => void
    onConfirm: () => void
    noLabel?:string
    yesLabel?:string
    noLock?:boolean
    yesLock?:boolean
}
const ConfirmButton = (props: IProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>( !!props.isOpen )
    useEffect(() => {
        setIsOpen( !!props.isOpen )
    }, [props.isOpen])
    const onOpen = () => {
        setIsOpen(!isOpen)
    }
    const onClose = () => {
        if(props.onDesmiss)
            props.onDesmiss()
        if(props.noLock) return
        setIsOpen(false)
    }
    const onConfirm = () => {
        props.onConfirm()
        if(props.yesLock) return 
        setIsOpen(false) 
    }
    const btn = props.isPopover
        ?
        <Popover
            interactionKind="hover"
            position={props.popoverPosition}
            content={props.popover}
        >
            <Button 
                intent={props.buttonIntent} 
                large={props.buttonLarge}
                disabled={props.buttonDisabled}
                fill={props.buttonFill}
                minimal={props.buttonMinimal}
                className={props.buttonClassName}
                icon={props.buttonIcon} 
                onClick={onOpen}
            >
                {props.buttonLabel}
            </Button>   
        </Popover>
        :
        <Button 
            intent={props.buttonIntent} 
            large={props.buttonLarge}
            disabled={props.buttonDisabled}
            fill={props.buttonFill}
            minimal={props.buttonMinimal}
            className={props.buttonClassName}
            icon={props.buttonIcon} 
            onClick={onOpen}
        >
            {props.buttonLabel}
        </Button>
    return <>
        { btn }
        <Dialog
            className={`${props.dialogClasssName} ${props.dialogAddedClassName}`}
            isOpen={ isOpen }
            onClose={ isOpen ? onClose : onOpen } 
            title={props.dialogTitle}
        > 
            <div className="d-flex flex-column flex-grow-100">
                <div className="flex-grow-100 ">
                    { props.children } 
                </div>
                <ButtonGroup fill>
                    <Button intent={Intent.DANGER} fill large onClick={onClose}>
                        {__(props.noLabel || "No")}
                    </Button>
                    <Button intent={Intent.SUCCESS} fill large onClick={onConfirm}>
                        {__(props.yesLabel || "Yes")}
                    </Button>
                </ButtonGroup>
            </div>            
            <Button minimal onClick={onOpen} className="position-absolute top right px-3 py-2">
                <Icon icon="cross" size={20} color="#FFFFFF"/>
            </Button>
            
        </Dialog>
    </>
} 

export default ConfirmButton