import { IState, useMainStore } from "@/settings/zustand"
import { IMenuItem } from "@/libs/interfaces/layouts"
import { __, isCapability } from "@/libs/utilities"
import { Icon, Popover } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"

interface IProps {
    item: IMenuItem
    i: number
    parentRoute: string
}
const HeaderMenuUnderButton = ({item, i, parentRoute, ...props}: IProps) :JSX.Element => {
    const user = useMainStore(( state: IState ) => state.user)
    const isRole = isCapability(item.capability, user) 
    const isNotLogged: boolean = item.islogged === true && !user.id
    const isHidden: boolean = item.is_hidden
    if (isRole || isNotLogged || isHidden ) return <></>
    return <UnderMenu item={item} key={i}  i={i} parentRoute={parentRoute} {...props}/>
} 

export default HeaderMenuUnderButton

const UnderMenu = ({item, i, parentRoute, ...props}:IProps) : JSX.Element => {
    if( !Array.isArray(item.children) )
    {
        return <SingleButton 
            item={item} 
            i={i} 
            parentRoute={parentRoute} 
            {...props}
        />
    }
    const children : JSX.Element[] = item.children.map((menuItem, ii) => {
        return <UnderMenu 
            item={ menuItem } 
            key={ ii } 
            i={ ii } 
            parentRoute={ `${parentRoute}/${item.route}` } 
            {...props}
        />
    })
    return <Popover
        popoverClassName="p-0 menu-popover"
        className=" p-0 d-flex bg-dark "
        position={ i % 2 === 0 ? "right-top" : "right-top"}
        usePortal={false}
        enforceFocus
        interactionKind="hover"
        transitionDuration={ 50 }
        modifiers={{ arrow: { enabled: false } }}
        content={
            <div className=" podmenu-cont card2">
                { children }
            </div>
        }
    >
       <SingleButton item={item} i={i} parentRoute={ `${parentRoute}` } {...props}/> 
    </Popover>
}

const SingleButton = ({item, i, parentRoute, ...props}: IProps) :JSX.Element => {
    const location = useLocation()
    const navigate = useNavigate()
    const [rt, setRt] = useState<string>( `/${parentRoute}/${ item.route }` )
    useEffect(() => {
        setRt( `/${ parentRoute }/${ item.route }` )
    }, [ location, parentRoute, item ]) 
    //console.log( rt )
    const onRoute = () => {
        navigate( rt )
    }
    return <div
        className={`${ location.pathname.indexOf(rt) >= 0 ? "active " : ""} podmenu text-nowrap min-w-200`}  
        onClick={ onRoute }
    >
        { __(item.title) } 
        { !!item.children?.length && <Icon icon="caret-right" className="pl-3 ml-auto"/> }
    </div>
} 