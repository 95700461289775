import { ID, IMenuItem } from "@/libs/interfaces/layouts"
import Layouts, { getRealRoute, getRoute, moduleExists } from "@/libs/layouts"
import { IUser } from "@/settings/zustand"
import { isRole } from "@/settings/zustand/user"
import { ReactElement } from "react"
import { Route } from "react-router"

export const addRoute = (
    data: IMenuItem, 
    n: number, 
    ElComponento: any, 
    ElComponento2: any,
    user: IUser,
    views: any,
    routeItems : any[]
): any[] => {
    let key: string | ID | number = Math.random(), childrenToutes: any[] = []
    /*
    *   TODO:: 
    *   сечас роутинг формируется ещё и в "@libs/routing.getSignleRoute". 
    *   Задача - получать данные роутинга оттуда.
    */ 
    //если модуль отключён
    if( !moduleExists(data.module) ) return routeItems
    // если роут закрыт для незалогиненных, а ты такой и есть
    if(data.islogged && !user.id) {
        return routeItems
    }
    //если роут закрыт по роли, а у пользователя не хватает прав -- долой
    if(data.capability && !isRole( data.capability )){
        return routeItems
    } 
    //если есть фильтр (например -- event_type) и он не соответствует списку закэшированных в основном сторе -- долой
    /*
    if( data.route_filters ) {  
        const event_types: string[] = useMainStore.getState().event_types || []
        let yes = false 
        data.route_filters
            .forEach(( filter: any ) => {
                Object.keys( filter ).forEach( key => {
                    filter[key].forEach((fk : string) => { 
                        if(event_types.includes(fk)) {
                            yes = true
                        }
                    })
                })
                return yes 
            }) 
        if( !yes ) 
        {
            //console.log( data.route_filters )
            return routeItems
        }
    }
    */
    if ( !!data.children?.length ) {
        data.children.forEach((menuItem: IMenuItem, i: number) => {
            menuItem = {
                ...menuItem,
                route: `${data.route}/${menuItem.route}`,
                parent_route: data.route,
                realRoute: `${ getRealRoute(data) }/${ data.route }`,
                i: data.i * 1000 + i
            } 
            const added = addRoute(
                menuItem, 
                i, 
                ElComponento, 
                ElComponento2, 
                user,
                views,
                routeItems
            )
            childrenToutes.push(added)
        })
    }
    //console.log( childrenToutes)
    if (data.html) {
        ElComponento = views["HTMLView"]
        if (ElComponento) {
            key = data.id || Math.random()
            const routeItemData: ReactElement = <Route
                path={!!data.route ? `${data.route}/` : ''}
                element={
                    <ElComponento
                        {...{
                            extend_params: { ...Layouts().template.extend_params },
                            ...data
                        }}
                    />
                }
                key={ key }
            />
            routeItems = [...routeItems.filter(ri => ri.i !== data.i), routeItemData]
        }
    }
    else if (data.html_source) {
        ElComponento = views["HTMLSourceVew"]
        if (ElComponento) {
            key = data.id || Math.random()
            const routeItemData = <Route
                path={!!data.route ? `${data.route}/` : ''}
                element={
                    <ElComponento
                        {...{
                            extend_params: { ...Layouts().template.extend_params },
                            ...data
                        }}
                    />
                }
                key={ key }
            />
            routeItems = [...routeItems.filter(ri => ri.i !== data.i), routeItemData]
        }
    }
    else if (data.redirect) {
        // REDIRECT

    }
    else if (data.singled_data_type) {
        // TIPICAL FRONT VIEW OF SINGLE TYPE ELEMENT 
        const schemaElement = Layouts().schema[data.singled_data_type]

        const SingleComponento = schemaElement && schemaElement.view
            ?
            views[schemaElement.view]
            :
            views["SingledDataTypeView"]
        if (SingleComponento) {
            const childRoutes: any = schemaElement?.childRoutes
            if (childRoutes) {
                key = data.id || Math.random()
                const routeItemData: ReactElement = <Route
                    path={!!data.route ? `${data.route}/:id` : ''}
                    element={
                        <SingleComponento
                            {...{
                                extend_params: { ...Layouts().template.extend_params },
                                ...data,
                                hide_title: true
                            }}
                        />
                    }
                    key={ key }
                >
                    {
                        childRoutes?.map((cr: string, i:number) => {
                            //console.log( cr )
                            key = cr + i
                            return <Route path={cr} element={<div> childe route </div>} key={ key } />
                        })
                    }
                </Route>
                routeItems = [...routeItems.filter(ri => ri.i !== data.i), routeItemData]
            }
            else {
                key = data.id || Math.random()
                const routeItemData: ReactElement = <Route
                    path={!!data.route ? `${data.route}/:id` : ''}
                    element={
                        <SingleComponento
                            {...{
                                extend_params: { ...Layouts().template.extend_params },
                                ...data,
                                hide_title: true
                            }}
                        />
                    }
                    key={ key }
                />
                routeItems = [...routeItems.filter(ri => ri.i !== data.i), routeItemData]
            }
        }
    }
    else if (data.feed_data_type) {
        // FRONT FEED OF TYPE ELEMENTS 
        ElComponento = views["FeedDataTypeView"]
        const schemaElement = Layouts().schema[data.feed_data_type]
        const FeedComponento = schemaElement && schemaElement.feed_view
            ?
            views[schemaElement.feed_view]
            :
            views["FeedDataTypeView"]
        if (FeedComponento) {
            key = data.id || Math.random()
            const routeItemData: ReactElement = <Route
                path={!!data.route ? `${data.route}/*` : ''}
                element={
                    <FeedComponento
                        {...{
                            extend_params: { ...Layouts().template.extend_params },
                            ...data
                        }}
                    />
                }
                key={ key }
            /> 
            //if( data.feed_data_type === " PEFestProject" ) {
            //    console.log( data.feed_data_type )
            //    console.log( routeItemData ) 
            //}
            routeItems = [...routeItems.filter(ri => ri.i !== data.i), routeItemData]
        }
    }
    else if (data.single_data_type) {
        // ADMIN VIEW OF SINGLE TYPE ELEMENT
        ElComponento = views["DataView"]
        if (ElComponento) {
            key = data.id || Math.random()
            const routeItemData: ReactElement = <Route
                path={!!data.route ? `${data.route}/:id/*` : ''}
                element={
                    <ElComponento
                        {...{
                            extend_params: { ...Layouts().template.extend_params },
                            ...data
                        }}
                    />
                }
                key={ key }
            />
            routeItems = [...routeItems.filter(ri => ri.i !== data.i), routeItemData]
        }
    }  
    if (data.data_type) {
        // ADMIN FEED OF TYPE ELEMENTS
        ElComponento = views["DataTableView"]
        if (ElComponento) {
            key = data.id || Math.random()
            const routeItemData: ReactElement = <Route
                path={!!data.route ? `${data.route}/*` : ''}
                element={
                    <ElComponento
                        {...{
                            extend_params: { ...Layouts().template.extend_params },
                            ...data
                        }}
                    />
                }
                key={ key }
            /> 
            routeItems = [...routeItems.filter(ri => ri.i !== data.i), routeItemData]
        }
        ElComponento2 = views["DataView"]
        if (ElComponento2) {
            key = data.id || Math.random()
            const routeItemData2: ReactElement = <Route
                path={!!data.route ? `${data.route}/:id/*` : ''}
                element={
                    <ElComponento2
                        {...{
                            extend_params: { ...Layouts().template.extend_params },
                            ...data
                        }}
                    />
                }
                key={ key }
            />
            routeItems = [...routeItems.filter(ri => ri.i !== data.i), routeItemData2]
        }
    }
    else if (data.component) {
        // UNIQ COMPONENT  
        ElComponento = views[data.component]
        if (ElComponento) {
            const childRoutes: any = data.childRoutes
            if (childRoutes) {
                key = data.id || Math.random()
                const routeItemData: ReactElement = <Route
                    path={!!data.route ? `${ getRoute(data) }/*` : ''}
                    element={
                        <ElComponento
                            {...{
                                extend_params: { ...Layouts().template.extend_params },
                                ...data
                            }}
                        />
                    }
                    key={ key }
                >
                    {
                        childRoutes?.map((cr: string, i: number) => {
                            // console.log( cr )
                            return <Route path={cr} element={<div> childe route </div>} key={cr + i} />
                        })
                    }
                </Route>
                routeItems = [...routeItems.filter(ri => ri.i !== data.i), routeItemData]
            }
            else {
                key = data.id || Math.random()
                const routeItemData: ReactElement = <Route
                    path={!!data.route ? `${ getRoute(data) }/*` : ''}
                    element={
                        <ElComponento
                            {...{
                                extend_params: { ...Layouts().template.extend_params },
                                ...data
                            }}
                        />
                    }
                    key={ key }
                /> 
                
                routeItems = [...routeItems.filter(ri => ri.i !== data.i), routeItemData]
            }

        }
    }  
    // console.log( "key = ", key)
    return [...routeItems, ...childrenToutes]
} 