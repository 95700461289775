import { ID } from "@/libs/interfaces/layouts"
import { Loading } from "@/libs/useful"
import switchToBlog from "@/modules/pe-basic-module/hooks/switchToBlog"
import Feed from "@/modules/pe-basic-module/views/core/Feed"
import getDefEventRoutes from "@/modules/pe-topos-module/components/getDefEventRoutes"
import { IToposStore, useToposStore } from "@/modules/pe-topos-module/data/store"
import { useMainStore } from "@/settings/zustand"
import { useEffect } from "react"
import { Outlet, Route, Routes, useParams } from "react-router"
import { useFestDataStore } from "../../data/store"
import { useFestival } from "../../hooks/festival"
import AboutForm from "../about/AboutForm"
import RateSystemForm from "../about/RateSystemForm"
import ModerationForm from "../moderation/ModerationForm"
import ProjectForm from "../project/ProjectForm"
import ProjectListForm from "../projectList/ProjectListForm"
import MessagesForm from "../messages/MessagesForm"

/* Protopia Ecosystem component */
export interface IFestivalProps {

}
const Festival = (props: IFestivalProps): JSX.Element => {
    const params = useParams()
    const festId: ID = params.landId || "-1" 
    const isLoading = useFestival( true, festId )
    const eventRoutes = useToposStore((state: IToposStore) => state.eventRoutes)
    useEffect(() => {
        useFestDataStore.setState({ festId })
        useMainStore.setState({landId: festId})
        switchToBlog( festId )
    }, [festId]) 
    
    if( isLoading ) return <div className="h-100 w-100 flex-center">
        <Loading />
    </div> 
    return <Routes> 
        <Route element={<><Outlet /></>} >     
            <Route path="*" element={<AboutForm {...props} />} >  
                <Route path="insert-project" element={<></>} /> 
                <Route path="reglament" element={<></>} /> 
            </Route>   
            <Route path="rate-system" element={<RateSystemForm  {...props} />} /> 
            <Route path="members" element={<ProjectListForm  {...props} />} /> 
            <Route path="messages" element={ <MessagesForm /> } /> 
            <Route path="moderation" element={<ModerationForm  {...props} />} /> 
            <Route
                path="project/:id/*"  
                element={
                    <div className="w-100"> 
                        <ProjectForm  /> 
                    </div> 
                }
            />
            { getDefEventRoutes( eventRoutes) } 
        </Route>
    </Routes>
}
export default Festival