import React, { useEffect, useState } from 'react'
import { Button, Intent } from '@blueprintjs/core'
import { __ } from '@/libs/utilities'  
import { IProject } from '@/modules/pe-fest-module/data/interfaces'
import { isRole } from '@/settings/zustand/user'
import actions from '@/modules/pe-basic-module/data/actions'
import { UPDATE_SINGLE_TYPE } from '@/modules/pe-basic-module/data/actionTypes'
import { useFestProjects } from '@/modules/pe-fest-module/data/store'
import { useParams } from 'react-router'

interface ICorrectPrizeProps {
    item: IProject
}

const CorrectPrize = (props: ICorrectPrizeProps) : JSX.Element => {
    const {landId} = useParams()
    const[isEdit, setEdit] = useState(false)
    const [prize, setPrize] = useState(props.item.prize)
    useEffect(() =>
    {
        setPrize(props.item.prize)
    }, [props.item.prize])
    
    const onPrize = () =>
    {
       // actions -- correct prize
       const data = {
            data_type: "PEFestProject",
            id: props.item.id,
            item: { prize },
            landId
        }
        actions(UPDATE_SINGLE_TYPE, data).then( (resp: IProject) => {
            console.log( resp.prize )
            const currentProject = useFestProjects.getState().currentProject
            const setCurrentProject = useFestProjects.getState().setCurrentProject
            setEdit( false ) 
            setCurrentProject({
                ...currentProject,
                prize: resp.prize
            })
        })
    }
    const edit = () =>
    {
        return <div className="d-flex align-items-center ">
            <input 
                type="string"
                className='form-control0 input dark w_350'
                value={prize}
                onChange={ (evt) => setPrize( evt.currentTarget.value )}
            />
            <Button
                intent={Intent.SUCCESS}
                icon="tick"
                minimal
                className='hint hint--top'
                data-hint={__("Save")}
                onClick={onPrize}
            />
            <Button
                intent={Intent.DANGER}
                icon="cross"
                minimal
                className='hint hint--top'
                data-hint={__("Cancel")}
                onClick={ () => setEdit( false ) }
            />
        </div>
    }
    const noEdit = () =>
    {
        return <div className="row align-items-center ">
            <div className="text-nowrap mx-3">
                { props.item.prize }
            </div>
            <div className=" ">
                <Button
                    minimal
                    fill
                    icon="edit"
                    onClick={ () => setEdit( true ) }
                >
                    
                </Button>
            </div>
        </div>
    }
    return isRole([ "administrator", "track_moderator", "School_guardian" ])
        ?
        isEdit ? edit() : noEdit()
        :
        <>{props.item.prize}</>
}

CorrectPrize.propTypes = {}

export default CorrectPrize