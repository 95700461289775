import actions from "@/modules/pe-basic-module/data/actions" 
import { DIALOG_SIZE, ID } from "@/libs/interfaces/layouts"
import { Button, ButtonGroup, Dialog, Intent } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { IDestrict, IFestFilters, IPEFestSchool } from "../data/interfaces"
import { useDataStore } from "@/modules/pe-basic-module/data/store"
import { GET_FEED_ACTION, SET_FILTERS_ACTIONS } from "../data/actionTypes"
import { IFestStore,  useFestStore } from "../data/store"
import { __, sprintf } from "@/libs/utilities"
import { syncAction } from "../data/actions"

/* Protopia Ecosystem component */
export interface IFestSchoolFilterWidgetProps {

}
const FestSchoolFilterWidget = (props: IFestSchoolFilterWidgetProps): JSX.Element => {
    const params = useParams()
    const land_id: ID = params.landId || "-1"
    const PEFestSchool: IPEFestSchool[] = useDataStore((state: any) => state.PEFestSchool)
    const filters : IFestFilters = useFestStore( (state: IFestStore) => state.filters)
    
    const [isOpen, setIsOpen] = useState<boolean>( false ) 
    const [selected, setSelected] = useState<ID[]>( filters.schools )

    useEffect(() => { 
        actions(GET_FEED_ACTION, {data_type: "PEFestSchool", land_id}) 
    }, [])

    useEffect(() => {
        setSelected( filters.schools )
    }, [ filters.schools ])

    const getLabel = () => {
        if(!Array.isArray(filters.schools) ||  filters.schools.length === 1 ) {
            return __("Select schools") 
        }
        else if ( filters.schools.length === 2) {
            return PEFestSchool.filter(s => s.id === filters.schools[1])[0]?.title || "-error select-"
        }
        else {
            return sprintf( __("selected %s schools"), ( filters.schools.length - 1 ).toString()  )
        }
    }
    const onDialogHandle = () => {
        setIsOpen(!isOpen)
    }
    const onSelect = (school: IPEFestSchool) => {
        if(!Array.isArray( selected )) {
            setSelected([-1])
            return
        }
        let schools: ID[] = []
        if( !!selected.filter( s => s === school.id ).length ) {
            schools = selected.filter( s => s !== school.id )
            if(!schools.length) {
                schools = [-1]
            }
        }
        else { 
            schools = [ ...selected ] 
            schools.push(school.id) 
            //console.log( schools )
        }
        setSelected( schools )        
        
    }

    const clearSelect = () => {  
        setSelected(  [ -1 ] )  
    }

    const onFilter = () => { 
        syncAction(
            SET_FILTERS_ACTIONS, 
            { 
                filters:{
                    destrict: [-1],
                    schools: selected
                }
            }
        ) 
        // useFestStore.setState({
        //     filters : {
        //         ...filters,
        //         destrict: [-1],
        //         schools: selected
        //     }
        // })
        setIsOpen( false )
    }

    const onClose = () => {
        setSelected(  filters.schools )
        setIsOpen( false )
    }

    return <div className='pe-fest-school-filter-widget-container d-flex flex-grow-4 justify-content-end'>
        <Button minimal onClick={ onDialogHandle }>
            <div className="text-overflow ">
                {getLabel()}
            </div>
        </Button>
        <Dialog
            isOpen={ isOpen }
            onClose={ onClose }
            className={ DIALOG_SIZE.NORMAL }
            title={__("Select owner education organizations")}
        >
            <div className="h_300 overflow-y-auto my-3">
                <ScoolChooseList selected={selected} onSelect={onSelect} />
            </div>
            <ButtonGroup >
                <Button fill large intent={Intent.DANGER} onClick={onClose}>
                    {__("Close")}
                </Button>
                <Button large intent={Intent.WARNING} onClick={ clearSelect }>
                    {__("Clear select")}
                </Button>
                <Button fill large intent={Intent.SUCCESS} onClick={ onFilter }>
                    {__("Select")}
                </Button>
            </ButtonGroup>
        </Dialog>
    </div>
}
export default FestSchoolFilterWidget

export const ScoolChooseList = ({onSelect, selected}: any) => {
    const PEFestSchool: IPEFestSchool[] = useDataStore((state: any) => state.PEFestSchool) || []
    const filters : IFestFilters = useFestStore( (state: IFestStore) => state.filters)
    return <ButtonGroup fill vertical>
        {
            PEFestSchool.map(school => {
                const is: boolean = Array.isArray( selected ) && 
                    !selected?.filter( s => s === school.id ).length 
                return <Button 
                    key={school.id} 
                    minimal={ is } 
                    intent={ is ? Intent.NONE: Intent.DANGER }
                    fill  
                    className="p-4"
                    onClick={ () => onSelect( school ) }
                >
                    <div>
                        { school.title }
                    </div>
                </Button>
            })
        }
    </ButtonGroup>
}