import { __ } from "@/libs/utilities"
import actions from "@/modules/pe-basic-module/data/actions"
import { MUTATION_EXTERNAL_TOKEN_ACTION } from "@/modules/pe-basic-module/data/actionTypes"
import ExternalLogins from "@/modules/pe-basic-module/views/profile/externals"
import { IUser } from "@/settings/zustand"

/* Protopia Ecosystem component */
export interface ILayoutUnoginedExternalProps {
   
}
const LayoutUnLoginnedExternal = (props: ILayoutUnoginedExternalProps): JSX.Element => {
    
    const onCallBack = ( user: IUser, external: string, label:string ) => {
        // console.log( user, external, label )
        actions( MUTATION_EXTERNAL_TOKEN_ACTION, {user, external})
        .then(
            (response: any) => { 
                onResponse( response )
                window.location.reload()
            },
            ((error) => {  })
        )
    } 
    const onResponse = (response: any) => {

    }
    return <div className='pe-layout-unlogined-external-container mr-4'>
        <ExternalLogins onCallBack={onCallBack} /> 
        <div className="small mt-2">{__("Log in")}</div>
    </div>
}
export default LayoutUnLoginnedExternal