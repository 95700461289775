import { useEffect, useId, useState } from "react"
import IScalarForm from "../interface/IScalarForm"
import Scalar from "./Scalar"
import useScreenSize from "@/modules/pe-basic-module/hooks/useScreenSize"
import { Dialog } from "@blueprintjs/core"
import { __ } from "@/libs/utilities" 
import actions from "@/modules/pe-basic-module/data/actions"
import { GET_RUTUBE_DATA_ACTION } from "@/modules/pe-basic-module/data/actionTypes"

/* Protopia Ecosystem component */
export interface IRutubeProps {
   
}
const Rutube = (props: IScalarForm) : JSX.Element => {
    return <Scalar 
        { ...props } 
        enabledForm={<RutubeEnabled {...props} />}
        desabledForm={<RutubeDesabled {...props} />}
    />
} 
export default Rutube

const RutubeEnabled = (props: IScalarForm) : JSX.Element => {
    const[descr, setDescr] = useState<string>("")
    const onChange  = (evt: any) => {
        const value: string = evt.target.value 
        const id: string | false = getVideoId( value ) 
        console.log( id )
        setDescr(__( id ? "" : "Invalid video identifier" ))
        if( props.onChange && id )
        {
            props.onChange( id,  props.field, "" )
        }   
    }
    return <>
        <div className="w-100">
           <Player {...props} />
            <input  
                className = "form-control input dark" 
                value = {  props.value } 
                onChange={onChange}
            />  
        </div>        
        <div className=" small mt-1 text-danger ">
            {descr}
        </div>
    </>
}

const RutubeDesabled = (props: IScalarForm) : JSX.Element => {
    return <>
        <Player {...props} />
    </>
}

const Player = (props: IScalarForm) : JSX.Element => {
    const id = useId()
    const [isPlay, setIsPlay]   = useState<boolean>(false)
    const [width, setWidth]     = useState<number>(parseInt(props.width?.toString() || "400")  || 400)
    const [height, setHeight]   = useState<number>(parseInt(props.height?.toString() || "160") || 160)
    const [data, setData]       = useState<any>({})
    const windowSize = useScreenSize()
    useEffect(() => {
        const ytp: HTMLElement = document.getElementById(`rutube-player-${id}`)!
        setWidth(ytp.offsetWidth)
        setHeight(ytp.offsetHeight)
    }, [])
    useEffect(() => { 
        actions(GET_RUTUBE_DATA_ACTION, {id: props.value})
            .then(res => { 
                setData(res)
            })
    }, [ props.value ])
    const onPlay = () => {
        setIsPlay(!isPlay)
    }
    const onOpenDialog = () => {
        setIsPlay(false);
        setTimeout(() => {
            const dd = document.getElementById(`rutube-video-${id}`)
            console.log(dd)
            if(dd) {
                dd.innerHTML = data.html
            }
        }, 1200)
        
    }

    return <div style={{width, height}} id={`rutube-player-${id}`}>
    { data?.thumbnail_url }
    {
        !isPlay
            ?
            <Thumbnail 
                id={ props.value } 
                width={width} 
                height={height} 
                image={data?.thumbnail_url}
                onPlay={onPlay} 
            />
            :
            width  > 400 && 
            height > 160
                ?
                <iframe 
                    width={props.width ? props.width : props.height && typeof props.height === "number" ? props.height * 1.6 : "320"} 
                    height={props.height || "160"} 
                    src={`https://www.youtube.com/embed/${  props.value }?controls=2&autoplay=1`} 
                    title="YouTube video player"
                    allowFullScreen
                />
                :
                <>
                    <Thumbnail 
                        id={ props.value } 
                        width={props.width} 
                        height={props.height} 
                        image={data?.thumbnail_url}
                        onPlay={onPlay} 
                    />
                    <Dialog
                        isOpen={true}
                        onClose={onOpenDialog}
                    >
                        <div id={`rutube-video-${id}`} className="p-0">
                            <iframe
                                width={windowSize.width < 800 ? windowSize.width : 800 } 
                                height={480} 
                                src={`https://rutube.ru/play/embed/${ props.value }?autoplay=1`}
                                allow="clipboard-write; autoplay"
                                title="Video"
                                allowFullScreen
                            /> 
                        </div>
                    </Dialog>
                </>
    }        
    </div>
}

export const Thumbnail = (props: any) :JSX.Element => {
    return <div 
        className={`video-container position-relative ${props.isPlay ? "isPlay" : ""}`}  
        style={{width:props.width, height: props.height}}
        onClick={props.onPlay}
    >  
        <div 
            className={`thumbnail card-top-img1 `} 
            style={{
                backgroundImage:`url(${ props.image })`, 
                width:props.width, 
                height: props.height
                }}  
            onClick={props.onPlay}
        /> 
        <svg 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="video-icon" 
        >
            <path className="round" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="#00000077" stroke="#FFFFFF" strokeWidth=".33" strokeLinecap="round" strokeLinejoin="round"/>
            <path className="tre" d="M15.0015 11.3344C15.3354 11.5569 15.5023 11.6682 15.5605 11.8085C15.6113 11.9311 15.6113 12.0689 15.5605 12.1915C15.5023 12.3318 15.3354 12.4431 15.0015 12.6656L11.2438 15.1708C10.8397 15.4402 10.6377 15.5749 10.4702 15.5649C10.3243 15.5561 10.1894 15.484 10.1012 15.3674C10 15.2336 10 14.9908 10 14.5052V9.49481C10 9.00923 10 8.76644 10.1012 8.63261C10.1894 8.51601 10.3243 8.44386 10.4702 8.43515C10.6377 8.42515 10.8397 8.55982 11.2438 8.82917L15.0015 11.3344Z" stroke="#FFFFFF" strokeWidth=".33" strokeLinecap="round" strokeLinejoin="round"/>
        </svg> 
    </div>
} 

const getVideoId = (urlOrId: string): string | false => {
    if( urlOrId.indexOf("http") > -1 ) {
        let mod = urlOrId.split("video/")[1] 
        if(!!mod) {
            return mod.replaceAll("/", "")
        }
        else return false
    }
    else if(!!urlOrId.split("/").length) {
       return urlOrId
    }
    else return false
}
const getData = (id:string) : Promise<any> => {
    return fetch(
        `https://rutube.ru/api/video/${id}`, 
        {
            mode: "cors",
            credentials: "include",
            headers: {
              "Content-Type": "application/json"
            }
        }
    )
}