import { IState, IUser, useMainStore } from '@/settings/zustand'
import { ID, WINDOW_WIDTH } from '@/libs/interfaces/layouts'
import { Loading } from '@/libs/useful'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Feed from 'src/modules/pe-basic-module/views/core/Feed'
import { getQueryData } from 'src/libs/layouts'
import { CHANGE_CARD_SORT_ACTION, CHANGE_CARD_TYPE_ACTION } from '../../data/actionTypes'
import { actions } from '../../data/actions'
import { cardTypes } from '../../data/cardTypes'
import { CARD_SORT_TYPE, CARD_TYPE, IFestFilters, IFestival, IPEFestSchool, IProject, IProjectListProps } from '../../data/interfaces'
import { IFestDataStor, IFestStore, useFestDataStore, useFestStore } from '../../data/store'
import { getGQLPagingFilters } from '../../data/utils/getPaging'
import { useFestival } from '../../hooks/festival'
import { useMyProjects } from '../../hooks/project'
import PListLayout from './PListLayout'
import SimpleStrokeProject from './SimpleStrokeProject'
import { useSearchParams } from 'react-router-dom' 
import { isRole } from '@/settings/zustand/user'
import { useDataStore } from '@/modules/pe-basic-module/data/store'


const ProjectListForm = (props: IProjectListProps) : JSX.Element => {
    
    const params = useParams()
    const festId: ID = params.landId || "-1" 
    // пагинация для фильтров ТРЕКИ, СОТЫ, ЖАНРЫ
    const filters: IFestFilters     = useFestStore( (state:IFestStore) => state.filters )
    const cardType: CARD_TYPE       = useFestStore( (state: IFestStore) => state.cardType )
    const cardSort: CARD_SORT_TYPE  = useFestStore( (state: IFestStore) => state.cardSort )
    const search: string            = useFestStore( (state: IFestStore) => state.search )
    const isVerified: boolean       = useFestStore( (state:IFestStore) => state.isVerified )
    const isNotVerified: boolean    = useFestStore( (state:IFestStore) => state.isNotVerified )
    
    const festival: IFestival = useFestDataStore((state:IFestDataStor) => state.festival )
    const[offset, setOffset] = useState<number>(0)  
    const over = ``
    const [paging, setPaging] = useState<string>( getGQLPagingFilters( filters, cardSort, search, over ) )
    const [searchParams,] = useSearchParams()
    useEffect(()=> {
        const tracks = searchParams.get("track")
        if(tracks) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    tracks: [ Number(tracks) ]}
                })
        }
        const schools = searchParams.get("schools")
        if(schools) {
            useFestStore.setState({
                filters: {
                    ...filters, 
                    schools: schools.split(",") }
                })
        }
    }, [])
    useEffect(() => {
        setOffset(0) 
        setPaging( getGQLPagingFilters( filters, cardSort, search, over ) )
        
    }, [ filters, cardSort, search, isVerified, isNotVerified ])

    const isLoading = useFestival(true, festId) 

    // получаем список проектов текущего пользователя       
    const myProjects: IProject[] = useMyProjects( festId ) 
    const data_type = "PEFestProject"
    if(isLoading ) return <Loading />
    
    const onCardType = ( cardType: CARD_TYPE ) => { 
        actions(CHANGE_CARD_TYPE_ACTION, {cardType})
    }
    const onCardSort = ( cardSort: CARD_SORT_TYPE ) => { 
        actions(CHANGE_CARD_SORT_ACTION, { cardSort })
    } 

    const cardComponent = window.innerWidth < WINDOW_WIDTH.TABLET
        ?
        SimpleStrokeProject
        :
        festival.isChangeCardType
            ?
            cardTypes().filter( ct => ct.type === cardType )[0]?.card || SimpleStrokeProject
            :
            cardTypes().filter( ct => ct.type === festival.memberCardType )[0]?.card || SimpleStrokeProject
            
    return (
        <div className='mx-auto container'> 
            <Feed 
                data_type={data_type}  
                offset={offset} 
                setOffset={ offset => setOffset(offset) }
                paging={ paging }
                land_id={ festId } 
                class_name='row'
                feedBefore={ offset === 0 ? getMyProjects(myProjects, filters, search, cardSort, isVerified, isNotVerified) : [] }
                filter={ myProjects.map(p => p.id ) }
                containerClassName="container" 
                layoutComponent={ PListLayout }
                component={ cardComponent }
                topPagiHide
                params={{
                    fields: getQueryData( data_type ),
                    route: props.route,
                    cardType,
                    cardSort, 
                    onCardSort,
                    onCardType
                }}
            />
        </div>
    )
} 

export default ProjectListForm

// проекты текущего пользователя, отфильтрованные по глобальному фильтру
const getMyProjects = (myProjects: IProject[], filters: IFestFilters, search: string, cardSort: CARD_SORT_TYPE, isVerified:boolean, isNotVerified:boolean ): IProject[] => { 
    if( search ) return [] 
    const schools : IPEFestSchool[] = useDataStore.getState().PEFestSchool || []
    const prs: IProject[] = myProjects.filter((p:IProject) => {
        if(filters.tracks[0] !== -1 ) {
            return p.track?.id === filters.tracks[0]
        }
        if(filters.honeycombs[0] !== -1 ) {
            return p.honeycombs?.id === filters.honeycombs[0]
        }
        if(filters.ganres[0] !== -1 ) {
            return false
        }
        if(filters.destrict[0] !== -1) {
            console.log( filters.destrict[0] )
            return filters.destrict[0]
                ?
                !![...schools].filter(fmru_school => {
                    return  fmru_school.destrict[0] && fmru_school.destrict[0]?.id === filters.destrict[0]
                }).length
                :
                false
        }
        if(filters.schools.length > 1) {
            return !!p.fmru_school.filter(fmru_school => {
                return filters.schools.includes(fmru_school.id)
            }).length 

        }
        if(isVerified && isNotVerified) {}
        else if( isVerified)
            return !!p.is_verified 
        else if( isNotVerified )
            return !p.is_verified 
        return true
    })
    prs.sort((a: IProject, b: IProject): number => {
        if( cardSort === CARD_SORT_TYPE.ACS) {
            if( a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() )
                return 1
            else return -1
        }
        if( cardSort === CARD_SORT_TYPE.DECS) {
            if( a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() )
                return -1
            else return 1
        }
        if( cardSort === CARD_SORT_TYPE.NACS) {
            if( a.order > b.order )
                return 1
            else return -1
        }
        if( cardSort === CARD_SORT_TYPE.NDECS) {
            if( a.order > b.order )
                return -1
            else return 1
        }
        if( cardSort === CARD_SORT_TYPE.RACS) {
            if( a.raiting > b.raiting )
                return 1
            else return -1
        }
        if( cardSort === CARD_SORT_TYPE.RDECS) {
            if( a.raiting > b.raiting )
                return -1
            else return 1
        }

        return 0
    })
    return prs
}