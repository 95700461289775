import gql from "graphql-tag";
import Layouts from "..";
import { ID, IAppoloField } from "src/libs/interfaces/layouts";
const ls = {
  name: "ecosystem",
  link_type: "http"
};
export function getAllDataTypes() {
  return Layouts().schema;
}
export default ls;
export function getMutationArgs(data_type: string) {
  if (!Layouts().schema[data_type]) {     
    throw new Error( ` unknoun data type: ${ data_type }` )
  }
  const {
    apollo_fields
  } = Layouts().schema[data_type];

  if (!apollo_fields) {
    throw new Error( ` unknoun data type fields: ${ data_type }` ) 
  }

  const fl = [];

  for (const fld in apollo_fields) {
    const field = apollo_fields[fld];
    if ( field.editable) {
      switch (field.kind) {
        case "type":
          break;

        case "scalar":
        default:
          fl.push(fld);
          break;
      }
    }
      
  }

  return fl.filter(e => e !== "id").join(" ");
}
export function getQueryData(data_type: string) {
  return Layouts().schema[data_type];
}
export function getQueryArgs(data_type: string, thread_only = false) {
  if (!Layouts().schema[data_type]) {     
    throw new Error( ` unknoun data type: ${ data_type }` )
  }

  const {
    apollo_fields
  } = Layouts().schema[data_type];


  if (!apollo_fields) {
    throw new Error( ` unknoun data type fields: ${ data_type }` ) 
  }

  if (thread_only) return apollo_fields;
  const fl = [];

  for (const fld in apollo_fields) { 
    const field = apollo_fields[fld];
    if(field.hide_query || field.hidden) {
      continue
    }
    switch (field.kind) {
      case "type":
        // console.log(Layouts().schema[field.component].apollo_fields);
        // console.log(field );
        const subfields: any = {};
        if(Array.isArray(field.external_fields))
        { 
          field.external_fields.forEach((ef:string) => subfields[ef] = "")
        }
        else {

          for (const k in Layouts().schema[field.component].apollo_fields) { 
            const e = Layouts().schema[field.component].apollo_fields[k];  
            // если поле -- скаляр
            if ( e.kind !== "type" && !e.hide_by_child && !e.hidden ) {
              subfields[k] = e;
            }
            // если поле -- компонент, но указаны суб-поля
            else if(  e.kind === "type" && Array.isArray(e.subfields) && !e.hide_by_child && !e.hidden  ) {
              subfields[`${k} {${e.subfields.join(" ")}}`] = e
            }
          }
        } 
        const fll = Object.keys(subfields);
        fl.push(`${fld} { ${fll.join(" ")} } `);
        // console.log( fl )
        break;

      case "scalar":
      default:
        //console.log(fld);
        fl.push(fld);
        break;
    }
  }

  return fl.join(" ");
}
export function mutationEditName(data_type: string) {
  return `change${data_type}`;
}
export function getQueryName(data_type: string) {
  return `get${data_type}s`;
} // data_type ? data_query : "get" + data_type + "s";

export function queryCollection(data_type: string, query_name: string, query_args: any, filter: string) {
  if (!Layouts().schema[data_type]) return "";
  const fields = query_args;
  filter = filter ? `(paging: ${filter})` : "";
  const query = `query ${query_name} { ${query_name} ${filter} { ${fields} } }`; // console.log( query );

  return gql`${query}`;
}
export function queryCollectionFilter(data_type: string, query_name: string, query_args: any, filter: string = "") {
  const fields = query_args;
  const paging1 = filter ? "($paging: Paging)" : "";
  const paging2 = filter ? "(paging: $paging)" : "";
  const query = `query ${query_name}${paging1} { ${query_name}${paging2} { ${fields} } }`; // console.log( query );

  return gql`${query}`;
}
export function getMutationName(data_type: string) {
  return `change${data_type}`;
}
export function getInputTypeName(data_type: string) {
  return `${data_type}Input`;
}
export function mutationAdd(data_type: string, mutation_name: string, input_type_name: string, mutation_args: any) {
  const fields = mutation_args;
  const mutation_change = `mutation ${mutation_name}($id:String $input:${input_type_name}){${mutation_name}(id: $id, input: $input){ ${fields} }}`;
  return gql`${mutation_change}`;
}
export function mutationEdit(
  data_type: string = "",
  mutation_name: string,
  input_type_name: string,
  mutation_args: any,
  landId: ID=""
) { 
  //console.log( landId )
  const fields = mutation_args;
  const site : string[] = !!landId ? [` $land_id: ID `, `land_id: $land_id `] : [``,``]
  //console.log( site )
  const mutation_change = `mutation ${mutation_name}($id:String $input:${input_type_name} ${site[0]}) { ${mutation_name}(id: $id, input: $input ${site[1]}){ ${fields} } }`;
  // console.log( mutation_change )
  return gql`${mutation_change}`;
}
export function mutationDelete(data_type: string) {
  const mutation_change = `mutation delete${data_type}($id:String!){ delete${data_type}(id: $id)}`;
  return gql`${mutation_change}`;
} // login

export function mutationAvtorize() {
  const mutation_change = "mutation authorize($input: AuthorizeInput){ authorize(input: $input){" + "auth_req_id" + "}}";
  return gql`${mutation_change}`;
}
export function mutationToken() {
  return token();
}
export function token() {
  const mutation_change = "mutation token($input: TokenInput!){ token(input: $input){" + "        access_token\n" + "        token_type\n" + "        expires_in\n" + "        refresh_token" + "}}";
  return gql`${mutation_change}`; 
}
export function externalToken() {
  const mutation_change = "mutation external_token($input: ExternalTokenInput){ external_token(input: $input){" + "        access_token\n" + "        token_type\n" + "        expires_in\n" + "        refresh_token" + "}}";
  return gql`${mutation_change}`;
}
export function queryUserInfo( fld: any, landId: number ) {
  const query_args = getQueryArgs("User");
  const fields = query_args;
  const land = landId && landId !== -1
    ?
    `(land_id: ${landId})`
    :
    ``
   
  const query = `query  userInfo{ userInfo${land}{ ${ fields } } }`;
  return gql`${query}`;
}
export function userInfo(query_args: []) {
  const fields = query_args.join(" ");
  const mutation_change = `query  userInfo{  userInfo{ ${fields} } }`;
  return gql`${mutation_change}`;
}
export function onSaveGql(state: any, _id: ID) {
  if (state._id) {
    delete state._id;
    delete state.__typename;
  }

  const fl = [];
  const {
    apollo_fields
  } = Layouts().schema[state.data_type];

  for (const el in state) {
    let dating;
    let tt;

    switch (apollo_fields[el].type) {
      case "array":
        switch (apollo_fields[el].component) {
          case "date":
          case "boolean":
          case "integer":
          case "float":
            tt = [];

            for (const t in state[el]) {
              tt.push(state[el]);
            }

            dating = `[${tt.join(", ")}]`;
            break;

          case "string":
          default:
            tt = [];

            for (const t in state[el]) {
              tt.push(`'${state[el]}'`);
            }

            dating = `[${tt.join(", ")}]`;
            break;
        }

        break;

      case "checkbox":
        tt = [];

        for (const t in state[el]) {
          tt.push(`'${state[el]}'`);
        }

        dating = `[${tt.join(", ")}]`;
        break;

      case "geo":
        dating = `[${state[el][0]}, ${state[el][1]}]`;
        break;

      case "date":
      case "boolean":
      case "integer":
      case "float":
        dating = state[el];
        break;

      case "email":
      case "phone":
      case "string":
      default:
        dating = `'${state[el]}'`;
        break;
    }

    fl.push(`${el}:${dating}`);
  } //console.log(fl)

}
export function getQueryExternalFields(component: any, external_fields: []) {
  const aq = getQueryName(component);
  const fl = external_fields ? external_fields.join(" ") : "";
  const query = `query ${aq} { ${aq} { ${fl} } }`; //console.log(component, external_fields, query)

  return gql`${query}`;
}
export function getInput(state: any, data_type: string) {
  // delete state.current;
  // delete state.height;
  // delete state.isOpen;
  // delete state.allChecked;
  // delete state.checked;
  // Удаляем не-редактируемые поля, которые всё равно запретит сервер
  const {
    apollo_fields
  } = Layouts().schema[data_type];
  const st: any = {};

  for (const i in state) {
    if (apollo_fields[i] && apollo_fields[i].editable) {
      st[i] = state[i];
    }
  }

  return st;
  /*
    for ( i in apollo_fields )
    {
        if(!apollo_fields[i].editable)
            delete state[i];
      };
    return state;
  */
}
export function plural(data_type: string): string {
  return Layouts().schema[data_type].plural;
}
export function singleName(data_type: string): string {
  return Layouts().schema[data_type].name;
}
export function apolloFields(data_type: string): IAppoloField[] {
  return Layouts().schema[data_type].apollo_fields;
}
export function adminData(data_type: string) {
  return Layouts().schema[data_type].admin_data;
}
export function getFields(data_type: string) {
  let dataType = "";

  for (const i in Layouts().schema) {
    if (i === data_type) {
      dataType = i;
      break;
    }
  }

  const {
    apollo_fields
  } = Layouts().schema[dataType];

  if (dataType) {
    return apollo_fields;
  }

  return {
    _id: "string",
    title: {
      type: "string"
    }
  };
}
export function getVisibleValue(type: string) {
  const {
    schema
  } = Layouts();
  const visibled_value = type 
    ? 
    schema[type] && schema[type].visibled_value 
      ? 
      schema[type].visibled_value 
      : 
      "title" 
    : 
    "title";
  return visibled_value;
}
export function querySingleName(data_type: string) {
  return `get${data_type}`;
}
export function querySingle(data_type: string, query_name: string, query_args: any, id: ID, land_id: ID = "" ) {
  const fields = query_args;
  const site = land_id && land_id !== -1
    ?
    ` $land_id: ID`
    : 
    ``
  const site2 = land_id && land_id !== -1
    ?
    ` land_id: $land_id`
    : 
    ``
  const query = `query ${query_name}($id: String ${site}) { ${query_name}(id: $id ${site2}) { ${fields} } }`;
  return gql`${query}`;
}
export function getIdName() {
  return "id";
}
export function getExternalPostfix() {
  return "";
}
export function getArrayPostfix() {
  return "";
}